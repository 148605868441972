import React, { useEffect, useState } from 'react';
import { Moon, Sun } from 'lucide-react';
import { useTheme } from 'next-themes';

export const ThemeToggle = () => {
  const { theme, setTheme, systemTheme } = useTheme();
  const [mounted, setMounted] = useState(false);

  useEffect(() => setMounted(true), []);

  if (!mounted) return null;

  const isDark = (theme === 'system' ? systemTheme : theme) === 'dark';

  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setTheme(isDark ? 'light' : 'dark');
      }}
      className={`
        group relative h-6 w-12 rounded-full p-0.5 transition-all duration-300 ease-in-out
        hover:scale-105 active:scale-95
        ${isDark ? 'bg-slate-700' : 'bg-slate-200'}
      `}
      aria-label="Toggle theme"
    >
      {/* Switch Knob */}
      <div
        className={`
          relative h-5 w-5 rounded-full shadow-sm transition-all duration-500
          ${isDark ? 'translate-x-6 bg-slate-800' : 'translate-x-0 bg-white'}
        `}
      >
        {/* Sun Icon */}
        <Sun
          className={`
            absolute inset-0 m-auto h-3 w-3 text-amber-500 transition-all duration-500
            ${isDark ? 'rotate-90 scale-0 opacity-0' : 'rotate-0 scale-100 opacity-100'}
          `}
        />
        {/* Moon Icon */}
        <Moon
          className={`
            absolute inset-0 m-auto h-3 w-3 text-blue-400 transition-all duration-500
            ${isDark ? 'rotate-0 scale-100 opacity-100' : '-rotate-90 scale-0 opacity-0'}
          `}
        />
      </div>
    </button>
  );
};