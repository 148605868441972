import { useState } from 'react'
import { 
  ChevronDown,
  AlertTriangle,
  AlertCircle,
  Github, 
  Search, 
  Brain, 
  Loader2, 
  ChevronRight,
  RefreshCcw,
  X,
  Check,
} from 'lucide-react'
import { Avatar, AvatarFallback, AvatarImage } from '../components/ui/avatar'
import { Button } from '../components/ui/button'
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from '../components/ui/card'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '../components/ui/dropdown-menu'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../components/ui/tooltip"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  DialogClose
} from '../components/ui/dialog'
import { Input } from '../components/ui/input'
import { ScrollArea } from '../components/ui/scroll-area'
import { SelectLLMModel } from './select-llm-model'
import { Switch } from '../components/ui/switch'
import { Badge } from '../components/ui/badge'
import { Textarea } from '../components/ui/textarea'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../components/ui/collapsible"

import { useAuth } from '../lib/auth/auth-provider'
import { useAuthStore } from '../lib/auth/auth-interceptor'
import RepoDetailPage from '../components/repo-detail';
import { toast } from 'sonner'
import { 
  useRepositories, 
  useMarkRepository,
  useUnMarkRepository,
  useGetUser,
  useToggleVulnerabilityCheck,
  useSetBusinessLogic,
  useDeleteBusinessLogic,
  useAddRepository,
} from '../services/api-service'
import { ThemeToggle } from '../components/ui/theme-toggle'
import { motion, AnimatePresence } from 'framer-motion';

const pageVariants = {
  initial: { opacity: 0, y: 20 },
  enter: { 
    opacity: 1, 
    y: 0,
    transition: {
      duration: 0.5,
      when: "beforeChildren",
      staggerChildren: 0.1
    }
  },
  exit: { 
    opacity: 0, 
    y: -20,
    transition: {
      duration: 0.3
    }
  }
};

export function DashboardComponent() {
  const [selectedRepoId, setSelectedRepoId] = useState(null)
  const [isGitHubAppInstallDialogOpen, setIsGitHubAppInstallDialogOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('')
  const [currentPage, setCurrentPage] = useState('home')
  const [businessLogic, setBusinessLogic] = useState({});
  const [expandedBusinessLogic, setExpandedBusinessLogic] = useState({});
  const [vulnerabilityState, setVulnerabilityState] = useState({});
  const [loadingStates, setLoadingStates] = useState({
    repositories: {},   // For repository actions
    vulnerability: {}, // For vulnerability toggles
    businessLogic: {}, // For business logic buttons
    archive: {},      // For archive actions
    delete: {},       // For delete actions
    visibility: {}    // For visibility changes
  })
  const [modifiedBusinessLogic, setModifiedBusinessLogic] = useState({});
  const [appliedBusinessLogic, setAppliedBusinessLogic] = useState({});
  const [loadingAction, setLoadingAction] = useState({ type: null, id: null });
  const [isConnecting, setIsConnecting] = useState(false);

  const { handleLogout } = useAuth()

  const handleLogouts = () => {
    handleLogout(); // Calls the logout mutation
  };

  const { data: user, isUserLoading } = useGetUser();
  const { data: repositories, isLoading, isError, refetch } = useRepositories()
  const { mutate: markRepository } = useMarkRepository();
  const { mutate: unMarkRepository } = useUnMarkRepository();
  const { mutate: toggleVulnerabilityCheck } = useToggleVulnerabilityCheck();
  const { mutate: setBusinessLogics } = useSetBusinessLogic();
  const { mutate: deleteBusinessLogic } = useDeleteBusinessLogic();
  const addRepositoryMutation = useAddRepository();

  const sortRepositoriesByAutoReview = (repositories) => {
    return repositories.sort((a, b) => {
      // Primary sort: Prioritize repositories with auto review enabled
      if (a.acrs === "true" && b.acrs !== "true") return -1;
      if (a.acrs !== "true" && b.acrs === "true") return 1;
      
      // Secondary sort: Alphabetical order by repository name
      // Use case-insensitive comparison to ensure consistent sorting
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      
      // Tertiary sort: By most recently integrated if names are identical
      return new Date(b.created_at) - new Date(a.created_at);
    });
  };

  const filteredRepos = repositories 
  ? sortRepositoriesByAutoReview(repositories).filter((repo) =>
      repo.name.toLowerCase().includes(searchQuery.toLowerCase())
    ) 
  : [];

  const toggleRepoSelection = async (repo) => {
    // Set loading state immediately
    setLoading('repositories', repo.id, true);
    
    try {
      // Add a one-second delay before toggling
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      const newState = repo.acrs === "true" ? "false" : "true";
      const mutation = repo.acrs === "true" ? unMarkRepository : markRepository;
      
      // Await the mutation
      mutation(repo.id);
      
      // Update repo state
      repo.acrs = newState;
      repo.user = user.username
      
      toast.success(
        `Automatic code review is now ${
          newState === "true" ? "enabled" : "disabled"
        } for ${repo.name}.`
      );
    } catch (error) {
      toast.error(`Failed to toggle repository: ${error.message}`);
    } finally {
      // Ensure loading state is always turned off
      setLoading('repositories', repo.id, false);
    }
  };

  const handleToggleVulnerabilityCheck = async (repo) => {
    const currentState = vulnerabilityState[repo.id] ?? (repo.is_vulnerability_enabled === "true");
    setLoading('vulnerability', repo.id, true);
  
    try {
      await new Promise(resolve => setTimeout(resolve, 1000));
  
      await toggleVulnerabilityCheck({
        repoId: repo.id,
        enabled: !currentState
      });
      
      // Update both the vulnerability state and the repo state
      setVulnerabilityState((prevState) => ({
        ...prevState,
        [repo.id]: !currentState
      }));
      
      // Update the repo object
      repo.is_vulnerability_enabled = (!currentState).toString();
  
      toast.success(
        `Vulnerability check is now ${
          !currentState ? 'enabled' : 'disabled'
        } for ${repo.name}.`
      );
    } catch (err) {
      // Revert the state change on error
      setVulnerabilityState((prevState) => ({
        ...prevState,
        [repo.id]: currentState
      }));
      repo.is_vulnerability_enabled = currentState.toString();
      
      console.error("Error toggling repository state:", err);
      toast.error(`Failed to change the status for ${repo.name}.`);
    } finally {
      setLoading('vulnerability', repo.id, false);
    }
  };

  const handleBusinessLogicChange = (repo, value) => {
    if (!repo || typeof repo.id === "undefined") {
      console.error("Invalid repo object:", repo);
      return;
    }
  
    // Always store the value, even if it's an empty string
    setBusinessLogic((prev) => ({
      ...prev,
      [repo.id]: value
    }));
  
    // Compare with the last applied logic or original description
    const isModified = value !== (appliedBusinessLogic[repo.id] || repo.description);
    
    setModifiedBusinessLogic((prev) => ({
      ...prev,
      [repo.id]: isModified
    }));
  };

  // Function to toggle business logic expansion for a specific repo
  const toggleBusinessLogicExpansion = (repoId) => {
    setExpandedBusinessLogic(prev => {
      // Create a new state where only the current repo is expanded
      const newState = {};
      newState[repoId] = !prev[repoId];
      return newState;
    });
  };
    
  const clearBusinessLogic = async (repo) => {
    try {
      setLoadingAction({ type: 'clear', id: repo.id });
      
      await deleteBusinessLogic({ 
        id: repo.id
      });

      // Clear the local state
      setBusinessLogic(prev => {
        const newState = { ...prev };
        delete newState[repo.id];
        return newState;
      });

      setModifiedBusinessLogic(prev => {
        const newState = { ...prev };
        delete newState[repo.id];
        return newState;
      });

      setAppliedBusinessLogic(prev => {
        const newState = { ...prev };
        delete newState[repo.id];
        return newState;
      });

      // Clear the repo description as well
      repo.description = '';

      toast.success("Business logic removed successfully");
    } catch (error) {
      console.error("Error removing business logic:", error);
      toast.error("Failed to remove business logic");
    } finally {
      setLoadingAction({ type: null, id: null });
    }
  };


  const applyBusinessLogic = async (repo) => {
    const logicText = businessLogic[repo.id];
    
    if (!logicText || logicText.trim() === '') {
      toast.error('Please enter business logic before applying');
      return;
    }

    try {
      setLoadingAction({ type: 'apply', id: repo.id });

      await new Promise(resolve => setTimeout(resolve, 1000));

      setBusinessLogics({
        id: repo.id,
        businessLogic: logicText
      });
      
      // Update the last applied logic
      setAppliedBusinessLogic((prev) => ({
        ...prev,
        [repo.id]: logicText
      }));

      // Update the repository description
      repo.description = logicText;

      // Reset modification state
      setModifiedBusinessLogic((prev) => ({
        ...prev,
        [repo.id]: false
      }));
      
      toast.success(`Business logic applied for ${repo.name}`);
    } catch (error) {
      console.error("Error applying business logic:", error);
      toast.error(`Failed to apply business logic for ${repo.name}`);
    } finally {
      setLoadingAction({ type: null, id: null });
    }
  }


  const renderRepositories = () => {
    if (isLoading) {
      return (
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="flex flex-col items-center justify-center h-full py-16"
        >
          <div className="relative">
            <div className="absolute inset-0 blur-xl bg-gradient-to-r from-primary/30 to-blue-600/30 animate-pulse" />
            <Loader2 className="h-16 w-16 animate-spin text-primary relative" />
          </div>
          <p className="text-2xl font-semibold mt-6 bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent">
            Loading repositories...
          </p>
        </motion.div>
      )
    }

    if (isError) {
      return (
        <motion.div 
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          className="flex flex-col items-center justify-center h-full text-center p-8"
        >
          <div className="relative p-8 rounded-2xl bg-red-50/50 dark:bg-red-900/20 backdrop-blur-sm">
            <div className="absolute inset-0 blur-2xl bg-gradient-to-r from-red-500/20 to-orange-500/20 animate-pulse rounded-2xl" />
            <AlertCircle className="h-20 w-20 text-red-500 mb-4 relative" />
            <h3 className="text-2xl font-bold text-red-600 dark:text-red-400 mb-2 relative">
              Oops! Something went wrong
            </h3>
            <p className="text-gray-600 dark:text-gray-300 mb-6 relative">
              Failed to load repositories. Please try again later.
            </p>
            <Button 
              onClick={() => refetch()} 
              variant="destructive" 
              className="relative bg-gradient-to-r from-red-500 to-red-600 hover:from-red-600 hover:to-red-700"
            >
              <RefreshCcw className="mr-2 h-4 w-4" /> Retry
            </Button>
          </div>
        </motion.div>
      )
    }

    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-6">
        {filteredRepos.map((repo, index) => (
          <motion.div
            key={repo.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
          >
            <Card className="
              group 
              flex flex-col
              relative
              transition-all 
              duration-300 
              border-0
              rounded-xl 
              overflow-hidden
              bg-white/50 dark:bg-gray-800/50
              backdrop-blur-xl
              hover:shadow-2xl
              hover:shadow-primary/10
              before:absolute
              before:inset-0
              before:bg-gradient-to-br
              before:from-primary/5
              before:to-blue-600/5
              before:opacity-0
              hover:before:opacity-100
              before:transition-opacity
            ">
              <div className="absolute inset-x-0 -top-px h-px bg-gradient-to-r from-transparent via-primary/30 to-transparent" />
              <div className="absolute inset-x-0 -bottom-px h-px bg-gradient-to-r from-transparent via-blue-600/30 to-transparent" />
              
              <CardHeader className="pb-2 relative">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-3">
                    <div className="h-9 w-9 rounded-lg ring-offset-2 ring-offset-background transition-all duration-300 group-hover:ring-primary flex items-center justify-center text-sm font-semibold text-white  bg-gradient-to-br from-primary/30 to-blue-600/50 backdrop-blur-sm">
                      {repo.name.slice(0, 2).toUpperCase()}
                    </div>
                    <div>
                      <CardTitle className="text-lg font-bold bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent group-hover:from-blue-600 group-hover:to-primary transition-all duration-300">
                        {repo.name}
                      </CardTitle>
                      <CardDescription className="text-sm line-clamp-1">
                        {repo.description || 'No description available'}
                      </CardDescription>
                    </div>
                  </div>
                  
                  <Badge 
                    variant={repo.isPrivate ? "destructive" : "secondary"}
                    className="px-3 py-1 text-xs font-medium uppercase tracking-wider bg-gradient-to-r from-primary/10 to-blue-600/10 backdrop-blur-sm"
                  >
                    {repo.isPrivate ? 'Private' : 'Public'}
                  </Badge>
                </div>

                <div className="flex items-center justify-between pt-4">
                  <TooltipProvider delayDuration={100}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <div className="flex items-center space-x-2"> 
                          <Switch
                            id={`repo-${repo.id}`}
                            checked={repo.acrs === "true"}
                            onCheckedChange={() => toggleRepoSelection(repo)}
                            className="
                              data-[state=checked]:bg-gradient-to-r 
                              data-[state=checked]:from-primary 
                              data-[state=checked]:to-blue-600
                              data-[state=unchecked]:bg-gray-200
                              dark:data-[state=unchecked]:bg-gray-700
                            "
                          />
                          <span className="text-sm font-medium text-gray-600 dark:text-gray-400">
                            Auto Review
                          </span>
                        </div>
                      </TooltipTrigger>
                      <TooltipContent 
                        side="top" 
                        align="start"
                        className="z-50 relative"
                        sideOffset={5}
                      >
                        <div className="text-sm">
                          {repo.acrs === "true" 
                            ? "Disable Automatic Code Review" 
                            : "Enable Automatic Code Review"}
                        </div>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                  {loadingStates.repositories[repo.id] && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                    >
                      <Loader2 className="h-4 w-4 animate-spin text-primary" />
                    </motion.div>
                  )}
                </div>
              </CardHeader>
              
              <CardContent className="flex-grow flex flex-col space-y-4 pt-4 relative">
                {repo.acrs !== "true" ? (
                  <div className="flex-grow flex items-center justify-center text-center p-4">
                    <div className="space-y-2">
                      <div className="relative p-3 rounded-full bg-gray-100 dark:bg-gray-700 w-fit mx-auto">
                        <AlertCircle className="h-6 w-6 text-muted-foreground" />
                      </div>
                      <p className="text-sm font-medium text-muted-foreground">
                        Repository not integrated
                      </p>
                      <p className="text-xs text-muted-foreground/80">
                        Enable automatic review to access advanced features
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="space-y-4">
                    {/* Vulnerability Check Section */}
                    <div className="group flex items-center justify-between p-4 rounded-xl bg-gradient-to-r from-yellow-50/50 to-amber-50/50 dark:from-yellow-900/10 dark:to-amber-900/10 hover:from-yellow-50/70 hover:to-amber-50/70 dark:hover:from-yellow-900/20 dark:hover:to-amber-900/20 transition-all duration-300 shadow-sm hover:shadow-md hover:shadow-yellow-500/10">
                      <div className="flex items-center space-x-3">
                        <div className="p-2 rounded-lg bg-yellow-100/50 dark:bg-yellow-500/10 group-hover:bg-yellow-100 dark:group-hover:bg-yellow-500/20 transition-colors duration-300">
                          <AlertTriangle className="h-5 w-5 text-yellow-600 dark:text-yellow-500" />
                        </div>
                        <div className="space-y-0.5">
                          <span className="text-sm font-semibold text-yellow-900 dark:text-yellow-300">
                            Vulnerability Check
                          </span>
                          <p className="text-xs text-yellow-600/80 dark:text-yellow-400/80">
                            Scan repository for security vulnerabilities
                          </p>
                        </div>
                      </div>
                      
                      <div className="flex items-center">
                        {loadingStates.vulnerability[repo.id] ? (
                          <div className="p-1 rounded-full bg-yellow-100/50 dark:bg-yellow-500/10">
                            <Loader2 className="h-4 w-4 animate-spin text-yellow-600 dark:text-yellow-500" />
                          </div>
                        ) : (
                          <Switch
                            checked={repo.is_vulnerability_enabled === "true"}
                            onCheckedChange={() => handleToggleVulnerabilityCheck(repo)}
                            disabled={loadingStates.vulnerability[repo.id]}
                            className="
                              bg-yellow-100 dark:bg-yellow-900/40
                              data-[state=checked]:bg-gradient-to-r
                              data-[state=checked]:from-yellow-500
                              data-[state=checked]:to-amber-500
                              transition-all duration-300
                              h-6 w-11
                              shadow-sm
                              hover:shadow-md hover:shadow-yellow-500/20
                            "
                          >
                            <span className="
                              block h-5 w-5 rounded-full
                              bg-white dark:bg-gray-100
                              shadow-sm
                              transition-transform duration-300
                              data-[state=checked]:translate-x-5
                              data-[state=unchecked]:translate-x-0
                            " />
                          </Switch>
                        )}
                      </div>
                    </div>

                    {/* Business Logic Section */}
                    <div className="relative">
                      <Collapsible 
                        open={expandedBusinessLogic[repo.id] === true} 
                        onOpenChange={() => toggleBusinessLogicExpansion(repo.id)}
                        className="transition-all duration-300 ease-in-out"
                      >
                        <CollapsibleTrigger className="w-full">
                          <motion.div 
                            className="group flex items-center justify-between p-4 rounded-xl bg-gradient-to-r from-green-50/50 to-emerald-50/50 dark:from-green-900/10 dark:to-emerald-900/10 hover:from-green-50/70 hover:to-emerald-50/70 dark:hover:from-green-900/20 dark:hover:to-emerald-900/20 transition-all duration-300 shadow-sm hover:shadow-md hover:shadow-green-500/10"
                            initial={false}
                            animate={{
                              backgroundColor: expandedBusinessLogic[repo.id] ? "rgba(167, 243, 208, 0.1)" : "rgba(167, 243, 208, 0)",
                            }}
                            transition={{ duration: 0.3 }}
                          >
                            <div className="flex items-center space-x-3">
                              <div className="p-2 rounded-lg bg-green-100/50 dark:bg-green-500/10 group-hover:bg-green-100 dark:group-hover:bg-green-500/20 transition-colors duration-300">
                                <Brain className="h-5 w-5 text-green-600 dark:text-green-500" />
                              </div>
                              <div className="space-y-0.5 text-left">
                                <span className="text-sm font-semibold text-green-900 dark:text-green-300">
                                  Description
                                </span>
                                <p className="text-xs text-green-600/80 dark:text-green-400/80 text-left">
                                  Add business logic description for AI analysis
                                </p>
                              </div>
                            </div>
                            
                            <motion.div
                              initial={false}
                              animate={{ 
                                rotate: expandedBusinessLogic[repo.id] ? 180 : 0 
                              }}
                              transition={{ duration: 0.3, ease: "easeInOut" }}
                            >
                              <ChevronDown className="h-4 w-4 text-green-600 dark:text-green-500" />
                            </motion.div>
                          </motion.div>
                        </CollapsibleTrigger>
                        
                        <CollapsibleContent className="relative overflow-hidden" forceMount>
                          <AnimatePresence initial={false} mode="wait">
                            {expandedBusinessLogic[repo.id] && (
                              <motion.div
                                initial={{ height: 0 }}
                                animate={{ 
                                  height: "auto",
                                  transition: {
                                    duration: 0.5,
                                    ease: [0.32, 0.72, 0, 1] // Custom easing for smoother motion
                                  }
                                }}
                                exit={{ 
                                  height: 0,
                                  transition: {
                                    duration: 0.4,
                                    ease: [0.32, 0.72, 0, 1]
                                  }
                                }}
                                className="relative"
                              >
                                <motion.div
                                  initial={{ opacity: 0 }}
                                  animate={{ 
                                    opacity: 1,
                                    transition: {
                                      duration: 0.3,
                                      ease: "easeOut",
                                      delay: 0.1
                                    }
                                  }}
                                  exit={{ 
                                    opacity: 0,
                                    transition: {
                                      duration: 0.2,
                                      ease: "easeIn"
                                    }
                                  }}
                                  className="space-y-3 pt-3"
                                >
                                  <div className="rounded-lg border border-green-100 dark:border-green-800/30 overflow-hidden bg-gradient-to-br from-green-50/50 to-emerald-50/50 dark:from-green-900/10 dark:to-emerald-900/10">
                                    <div className="p-4 space-y-4">
                                      {/* Description Header */}
                                      <div className="flex items-center justify-between">
                                        <div className="space-y-1">
                                          <h4 className="text-sm font-semibold text-green-800 dark:text-green-300">
                                            Repository Description
                                          </h4>
                                          <p className="text-xs text-green-600/80 dark:text-green-400/80">
                                            Add or modify the business logic description for AI analysis
                                          </p>
                                        </div>
                                        {(modifiedBusinessLogic[repo.id] || repo.description) && (
                                          <div className="flex items-center space-x-2">
                                            <Badge 
                                              variant="outline" 
                                              className={`
                                                px-2 py-1 text-xs
                                                ${modifiedBusinessLogic[repo.id] 
                                                  ? 'bg-amber-50 text-amber-700 border-amber-200 dark:bg-amber-900/20 dark:text-amber-400 dark:border-amber-700/30'
                                                  : 'bg-green-50 text-green-700 border-green-200 dark:bg-green-900/20 dark:text-green-400 dark:border-green-700/30'}
                                              `}
                                            >
                                              {modifiedBusinessLogic[repo.id] ? 'Modified' : 'Saved'}
                                            </Badge>
                                          </div>
                                        )}
                                      </div>

                                      {/* Textarea Section */}
                                      <div className="relative">
                                        <Textarea
                                          placeholder="Enter business logic description"
                                          value={businessLogic[repo.id] === undefined ? repo.description || '' : businessLogic[repo.id]}
                                          onChange={(e) => handleBusinessLogicChange(repo, e.target.value)}
                                          className="w-full min-h-[120px] bg-white/80 dark:bg-gray-900/50 
                                            backdrop-blur-sm border-green-200/50 dark:border-green-800/30 
                                            focus:border-green-500 dark:focus:border-green-500 
                                            transition-colors rounded-lg resize-none
                                            placeholder:text-green-900/60 dark:placeholder:text-green-400/60
                                            text-green-800 dark:text-green-300"
                                        />
                                        <div className="absolute bottom-2 right-2">
                                          <span className="text-xs text-green-600/40 dark:text-green-400/40">
                                            Describe the business logic for better AI analysis
                                          </span>
                                        </div>
                                      </div>

                                      {/* Action Buttons */}
                                      <div className="grid grid-cols-2 gap-3">
                                        <Button 
                                          variant="outline" 
                                          size="sm" 
                                          onClick={() => clearBusinessLogic(repo)}
                                          disabled={(!repo.description && !businessLogic[repo.id]) || loadingAction.id === repo.id}
                                          className={`
                                            relative w-full
                                            border-red-200 dark:border-red-800/30 
                                            text-red-700 dark:text-red-400
                                            transition-all duration-200
                                            ${(!repo.description && !businessLogic[repo.id]) 
                                              ? 'opacity-50 cursor-not-allowed' 
                                              : 'hover:bg-red-50 dark:hover:bg-red-900/50'}
                                          `}
                                        >
                                          {loadingAction.type === 'clear' && loadingAction.id === repo.id ? (
                                            <div className="flex items-center justify-center">
                                              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                              <span>Clearing...</span>
                                            </div>
                                          ) : (
                                            <div className="flex items-center justify-center">
                                              <X className="mr-2 h-4 w-4" />
                                              <span>Clear Description</span>
                                            </div>
                                          )}
                                        </Button>
                                        <Button 
                                          size="sm" 
                                          onClick={() => applyBusinessLogic(repo)}
                                          disabled={!modifiedBusinessLogic[repo.id] || loadingAction.id === repo.id}
                                          className={`
                                            relative w-full
                                            bg-gradient-to-r from-green-500 to-emerald-500 
                                            hover:from-green-600 hover:to-emerald-600
                                            disabled:from-green-500/50 disabled:to-emerald-500/50
                                            text-white
                                            transition-all duration-300
                                            ${loadingAction.type === 'apply' && loadingAction.id === repo.id ? 'opacity-80' : ''}
                                          `}
                                        >
                                          {loadingAction.type === 'apply' && loadingAction.id === repo.id ? (
                                            <div className="flex items-center justify-center">
                                              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                              <span>Applying...</span>
                                            </div>
                                          ) : (
                                            <div className="flex items-center justify-center">
                                              <Check className="mr-2 h-4 w-4" />
                                              <span>Apply Changes</span>
                                            </div>
                                          )}
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </motion.div>
                              </motion.div>
                            )}
                          </AnimatePresence>
                        </CollapsibleContent>
                      </Collapsible>
                    </div>
                  </div>
                )}

                {/* File Selection Section */}
                <div className="mt-auto flex items-center justify-between space-x-4 pt-4">
                  {repo.acrs === "true" && repo.user && (
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      Integrated by {repo.user}
                    </span>
                  )}
                  {repo.acrs === "true" && (
                    <Button 
                      variant="outline"
                      size="sm"
                      className="
                        group/btn
                        relative
                        overflow-hidden
                        border-primary/20
                        hover:border-primary
                        transition-colors
                      "
                      onClick={() => {
                        setSelectedRepoId(repo.id);
                        setCurrentPage('repo-detail');
                      }}
                    >
                      <div className="absolute inset-0 bg-gradient-to-r from-primary/10 to-blue-600/10 opacity-0 group-hover/btn:opacity-100 transition-opacity" />
                      <span className="relative flex items-center">
                        Select Ignore Files
                        <ChevronRight 
                          className="
                            ml-2 
                            h-4 
                            w-4 
                            group-hover/btn:translate-x-1 
                            transition-transform
                          " 
                        />
                      </span>
                    </Button>
                  )}
                </div>
              </CardContent>
            </Card>
          </motion.div>
        ))}
      </div>
    );
  };

  const renderPage = () => {
    switch (currentPage) {
      case 'select-llm':
        return (
          <motion.div
            key="select-llm"
            variants={pageVariants}
            initial="initial"
            animate="enter"
            exit="exit"
          >
            <SelectLLMModel user={user} isUserLoading={isUserLoading} />
          </motion.div>
        );
      case 'repo-detail':
        const selectedRepository = repositories?.find(repo => repo.id === selectedRepoId);
        return (
          <motion.div
            key="repo-detail"
            variants={pageVariants}
            initial="initial"
            animate="enter"
            exit="exit"
          >
            <RepoDetailPage 
              repoId={selectedRepoId} 
              onBack={() => setCurrentPage('home')}
              repoName={selectedRepository?.name}
              repoStats={{
                stars: selectedRepository?.stars || 0,
                forks: selectedRepository?.forks || 0,
                watchers: selectedRepository?.watchers || 0
              }}
            />
          </motion.div>
        );
      default:
        return (
          <motion.div
            key="home"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div className="p-6">
              <ScrollArea className="h-[calc(100vh-7rem)]">
                {renderRepositories()}
              </ScrollArea>
            </div>
          </motion.div>
        );
    }
  };

  const setLoading = (type, id, isLoading) => {
    setLoadingStates(prev => ({
      ...prev,
      [type]: {
        ...prev[type],
        [id]: isLoading
      }
    }));
  };

  return (
    <div className="flex h-screen bg-gradient-to-br from-gray-50 to-gray-100 dark:from-gray-900 dark:to-gray-800">
      {/* Sidebar */}
      <motion.aside 
        initial={{ x: -20, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        className="
          w-64 
          bg-white/80 dark:bg-gray-800/80 
          backdrop-blur-xl 
          border-r border-gray-200 dark:border-gray-700 
          p-4 
          space-y-4
          relative
        "
      >
        <div className="absolute inset-0 bg-gradient-to-b from-primary/5 to-blue-600/5 pointer-events-none" />
        <div className="relative">
          <div className="flex items-center justify-between mb-6">
            <h1 className="text-2xl font-bold bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent">
              ACRS
            </h1>
            <Badge variant="secondary" className="animate-pulse bg-primary/10 text-primary">
              v1.0
            </Badge>
          </div>
          
          <nav className="space-y-2">
            <Button
              variant={currentPage === 'home' ? 'secondary' : 'ghost'}
              className="w-full justify-start font-medium relative overflow-hidden group"
              onClick={() => setCurrentPage('home')}
            >
              <div className="absolute inset-0 bg-gradient-to-r from-primary/10 to-blue-600/10 opacity-0 group-hover:opacity-100 transition-opacity" />
              <Github className="mr-2 h-4 w-4" />
              <span className="relative">Repositories</span>
            </Button>
            <Button
              variant={currentPage === 'select-llm' ? 'secondary' : 'ghost'}
              className="w-full justify-start font-medium relative overflow-hidden group"
              onClick={() => setCurrentPage('select-llm')}
            >
              <div className="absolute inset-0 bg-gradient-to-r from-primary/10 to-blue-600/10 opacity-0 group-hover:opacity-100 transition-opacity" />
              <Brain className="mr-2 h-4 w-4" />
              <span className="relative">AI Models</span>
            </Button>
          </nav>
        </div>
      </motion.aside>

      {/* Main content area */}
      <main className="flex-1 flex flex-col overflow-hidden">
        {/* Header */}
        <motion.header 
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          className="
            bg-white/80 dark:bg-gray-800/80 
            backdrop-blur-xl 
            border-b border-gray-200 dark:border-gray-700 
            p-4 
            shadow-sm
            relative
          "
        >
          <div className="absolute inset-0 bg-gradient-to-r from-primary/5 to-blue-600/5 pointer-events-none" />
          <div className="flex items-center justify-between relative">
            {/* Add this section for the page title */}
            <div className="flex items-center space-x-4">
              <h2 className="text-2xl font-bold bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent">
                {currentPage === 'home' ? 'My Repositories' : 
                currentPage === 'select-llm' ? 'AI Models' : 
                currentPage === 'repo-detail' ? 'Repository Details' : 
                'Dashboard'}
              </h2>
              
              {/* Conditionally render search and sync only on home page */}
              {currentPage === 'home' && (
                <motion.div 
                  initial={{ opacity: 0, x: -100 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ 
                    type: "tween",
                    duration: 0.5,
                    ease: "easeOut"
                  }}
                  className="flex items-center space-x-4"
                >
                  <motion.div 
                    initial={{ opacity: 0, x: -50 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ 
                      type: "tween", 
                      duration: 0.5,
                      delay: 0.1,
                      ease: "easeOut"
                    }}
                    className="relative flex-1 max-w-md"
                  >
                    <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground" />
                    <Input
                      placeholder="Search repositories..."
                      className="pl-10 bg-white/50 dark:bg-gray-900/50 backdrop-blur-sm border-gray-200 dark:border-gray-700 focus:border-primary dark:focus:border-primary transition-colors"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </motion.div>
                  
                  {/* Sync Repository Button - Only show for GitHub on home page */}
                  {useAuthStore.getState().vcrs === 'github' && (
                    <>
                      <motion.div
                        initial={{ opacity: 0, x: -50 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ 
                          type: "tween", 
                          duration: 0.5,
                          delay: 0.2,
                          ease: "easeOut"
                        }}
                      >
                        <Button
                          onClick={() => setIsGitHubAppInstallDialogOpen(true)}
                          className="
                            group
                            relative
                            bg-gradient-to-r from-primary to-blue-600
                            text-white
                            transition-all
                            duration-300
                          "
                        >
                          <RefreshCcw className={`h-4 w-4 mr-2 transition-transform duration-700 group-hover:rotate-180`} />
                          Sync Repositories
                        </Button>
                      </motion.div>

                      {/* GitHub App Installation Dialog */}
                      {/* Sync Repository Dialog */}
                      <Dialog 
                        open={isGitHubAppInstallDialogOpen} 
                        onOpenChange={setIsGitHubAppInstallDialogOpen}
                      >
                        <DialogContent className="sm:max-w-[600px] bg-white dark:bg-gray-900 rounded-2xl shadow-2xl">
                          <DialogHeader className="space-y-4">
                            <div className="flex items-center justify-center mb-4">
                              <div className="p-4 rounded-full bg-primary/10 dark:bg-primary/20">
                                <Github className="h-12 w-12 text-primary" />
                              </div>
                            </div>
                            <DialogTitle className="text-center text-3xl font-bold bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent">
                              Connect GitHub Repositories
                            </DialogTitle>
                            <DialogDescription className="text-center text-gray-600 dark:text-gray-300">
                              Select the repositories you want to analyze with ACRS
                            </DialogDescription>
                          </DialogHeader>
                          
                          <div className="space-y-4 bg-gray-50 dark:bg-gray-800/50 p-6 rounded-xl border border-gray-200 dark:border-gray-700">
                            <div className="flex items-start space-x-4">
                              <div className="bg-blue-100 dark:bg-blue-900/30 p-3 rounded-lg">
                                <AlertCircle className="h-6 w-6 text-blue-600 dark:text-blue-400" />
                              </div>
                              <div>
                                <h4 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                                  Repository Selection
                                </h4>
                                <div className="text-sm text-gray-600 dark:text-gray-400 mt-2">
                                  You'll be redirected to GitHub to:
                                  <ul className="list-disc list-inside mt-1">
                                    <li>Choose repositories for ACRS analysis</li>
                                    <li>Set access permissions</li>
                                    <li>Control which repos are monitored</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            
                            <div className="flex items-start space-x-4">
                              <div className="bg-green-100 dark:bg-green-900/30 p-3 rounded-lg">
                                <Brain className="h-6 w-6 text-green-600 dark:text-green-400" />
                              </div>
                              <div>
                                <h4 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                                  What ACRS Requires
                                </h4>
                                <div className="text-sm text-gray-600 dark:text-gray-400 mt-2">
                                  ACRS needs read access to:
                                  <ul className="list-disc list-inside mt-1">
                                    <li>Repository code</li>
                                    <li>Repository metadata</li>
                                    <li>Pull request information</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                          <DialogFooter className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
                            <Button 
                              type="button"
                              onClick={async () => {
                                setIsConnecting(true);
                                try {
                                  await addRepositoryMutation.mutate();
                                } catch (error) {
                                  setIsConnecting(false);
                                  // Error handling is already done in the mutation
                                }
                              }}
                              disabled={isConnecting}
                              className="
                                w-full 
                                bg-gradient-to-r from-primary to-blue-600 
                                hover:from-primary/90 hover:to-blue-600/90
                                text-white
                                py-3
                                rounded-xl
                                transition-all
                                duration-300
                                group
                                relative
                                overflow-hidden
                              "
                            >
                              <div className="absolute inset-0 bg-gradient-to-r from-white/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity" />
                              {isConnecting ? (
                                <>
                                  <Loader2 className="mr-2 h-5 w-5 animate-spin" />
                                  Connecting...
                                </>
                              ) : (
                                <>
                                  <Github className="mr-2 h-5 w-5" />
                                  Connect GitHub Repositories
                                </>
                              )}
                            </Button>
                            
                            <DialogClose asChild>
                              <Button 
                                type="button" 
                                variant="outline"
                                className="
                                  w-full 
                                  border-gray-300 
                                  dark:border-gray-700 
                                  hover:bg-gray-100 
                                  dark:hover:bg-gray-800
                                  rounded-xl
                                "
                              >
                                Cancel
                              </Button>
                            </DialogClose>
                          </DialogFooter>
                        </DialogContent>
                      </Dialog>
                    </>
                  )}
                </motion.div>
              )}
            </div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="relative h-8 w-8 rounded-full">
                  <Avatar className="h-8 w-8 transition-transform hover:scale-110">
                    <AvatarImage src="/placeholder-avatar.jpg" alt="@johndoe" />
                    <AvatarFallback className="bg-gradient-to-br from-primary/20 to-blue-600/20 text-primary">
                      {user?.username
                        .split(" ")
                        .map((namePart) => namePart[0]?.toUpperCase())
                        .join("")}
                    </AvatarFallback>
                  </Avatar>
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56" align="end" forceMount>
                <DropdownMenuLabel className="font-normal">
                  <div className="flex flex-col space-y-1">
                    <p className="text-sm font-medium leading-none">{user?.username}</p>
                    <p className="text-xs leading-none text-muted-foreground">
                      {user?.user_email}
                    </p>
                  </div>
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem asChild>
                <DropdownMenuItem className="flex items-center justify-between">
                  <span>Theme</span>
                  <ThemeToggle />
                </DropdownMenuItem>
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  onClick={handleLogouts}
                  className="text-red-600 dark:text-red-400 focus:text-red-700 dark:focus:text-red-300"
                >
                  Log out
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </motion.header>
        
        <AnimatePresence mode="wait">
          {renderPage()}
        </AnimatePresence>
      </main>
    </div>
  );
}