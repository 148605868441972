import { Link } from 'react-router-dom';
import { GitPullRequest, ArrowRight } from 'lucide-react';
import { Button } from "../components/ui/button";
import { useAuth } from '../lib/auth/auth-provider';
import { motion } from 'framer-motion';

export function WelcomePageComponent() {
  const { accessToken } = useAuth();

  return (
    <div className="relative min-h-screen bg-white dark:bg-gray-900">
      {/* Content */}
      <div className="relative flex flex-col items-center justify-center min-h-screen p-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center space-y-8 max-w-3xl px-4"
        >
          {/* Logo */}
          <div className="relative mb-8">
            <div className="absolute inset-0 rounded-full bg-gradient-to-r from-primary/20 to-blue-600/20 blur-2xl" />
            <div className="relative w-24 h-24 mx-auto">
              <motion.div 
                className="absolute inset-0 rounded-full border-2 border-primary/30"
                animate={{ scale: [1, 1.2, 1] }}
                transition={{ 
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              />
              <motion.div 
                className="absolute inset-0 rounded-full border-2 border-blue-600/30"
                animate={{ scale: [1.2, 1, 1.2] }}
                transition={{ 
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              />
              <div className="absolute inset-3 rounded-full bg-white dark:bg-gray-900 flex items-center justify-center">
                <GitPullRequest className="w-10 h-10 text-primary" />
              </div>
            </div>
          </div>

          {/* Title and Description */}
          <div className="space-y-6">
            <motion.h1 
              className="text-5xl sm:text-6xl md:text-7xl font-bold tracking-tight"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
            >
              <span className="bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent">
                Automatic Code
              </span>
              <br />
              <span className="bg-gradient-to-r from-blue-600 to-primary bg-clip-text text-transparent">
                Review System
              </span>
            </motion.h1>

            <motion.p 
              className="text-xl text-gray-600 dark:text-gray-400 max-w-2xl mx-auto"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
            >
              Streamline your repository management and harness the power of AI 
              to boost your productivity with automated code reviews.
            </motion.p>
          </div>

          {/* Action Buttons */}
          <motion.div 
            className="flex flex-col sm:flex-row gap-4 justify-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
          >
            {accessToken ? (
              <Button asChild size="lg" className="h-12 px-8 text-lg">
                <Link to="/dashboard" className="flex items-center gap-2">
                  Go to Dashboard
                  <ArrowRight className="w-5 h-5" />
                </Link>
              </Button>
            ) : (
              <Button asChild size="lg" className="h-12 px-8 text-lg">
                <Link to="/login" className="flex items-center gap-2">
                  Get Started
                  <ArrowRight className="w-5 h-5" />
                </Link>
              </Button>
            )}
            <Button 
              asChild 
              variant="outline" 
              size="lg" 
              className="h-12 px-8 text-lg"
            >
              <Link>Learn More</Link>
            </Button>
          </motion.div>

          {/* Features Grid */}
          <motion.div 
            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mt-16"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
          >
            {[
              { title: "AI-Powered", desc: "Advanced code analysis" },
              { title: "Automated", desc: "Streamlined workflow" },
              { title: "Efficient", desc: "Faster development cycles" }
            ].map((feature, index) => (
              <div 
                key={feature.title}
                className="p-6 rounded-xl bg-gray-50 dark:bg-gray-800/50"
              >
                <h3 className="font-semibold text-gray-900 dark:text-gray-100">
                  {feature.title}
                </h3>
                <p className="text-gray-600 dark:text-gray-400 text-sm">
                  {feature.desc}
                </p>
              </div>
            ))}
          </motion.div>
        </motion.div>

        {/* Footer */}
        <motion.footer 
          className="absolute bottom-0 w-full py-6 text-center text-sm text-gray-500 dark:text-gray-400"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.6 }}
        >
          © 2024 ACRS. All rights reserved.
        </motion.footer>
      </div>
    </div>
  );
}
