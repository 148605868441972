import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthStore } from './auth-interceptor';
import LoadingPage from '../../components/loading-page';

export const ProtectedRoute = ({ children }) => {
  const user_id = useAuthStore((state) => state.user);
  // const isLoading = useAuthStore((state) => state.isLoading);
  const location = useLocation();

  // if (isLoading) {
  //   return <LoadingPage />;
  // }

  if (!user_id) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

export const PublicRoute = ({ children }) => {
  const user_id = useAuthStore((state) => state.user);
  const isLoading = useAuthStore((state) => state.isLoading);

  if (isLoading) {
    return <LoadingPage />;
  }

  // If the user is authenticated, redirect to the dashboard
  if (user_id) {
    return <Navigate to="/dashboard" replace />;
  }

  return <>{children}</>;
};
