import React from 'react';
import FileTreeView from './components/FileTreeView';
import { FileSelectionProvider } from './context/FileSelectionContext';
import { getFileIcon } from './utils/fileIcons';

const FileTreeViewWrapper = ({ 
  data, 
  onSelectionChange, 
  initialSelectedFiles = [],
  initialSelectedFolders = [],
  backendState
}) => {
  return (
    <FileSelectionProvider 
      onSelectionChange={onSelectionChange}
      initialSelectedFiles={initialSelectedFiles}
      initialSelectedFolders={initialSelectedFolders}
      treeData={data}
      backendState={backendState}
    >
      <FileTreeView data={data} />
    </FileSelectionProvider>
  );
};

export { getFileIcon };
export default FileTreeViewWrapper;