import { ENDPOINTS } from '../constants/apiEndpoints'; // Import the constants
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import { api, useAuthStore } from '../lib/auth/auth-interceptor';
import { errorHandler } from '../lib/auth/error-handler'; // Adjust the path as necessary
import axios from 'axios';
import { isNetworkError, getErrorMessage } from '../utils/error-handler';

export const apiService = {
  // Handles the OAuth callback, sets the access token if present
  handleOAuthCallback: async (searchParams) => {
    // useAuthStore.getState().setLoading(true);
    try {
      const user_id = searchParams.get('user_id')
      const vcrs = searchParams.get('vcrs')
      if (user_id) {
        useAuthStore.getState().setUser(user_id);
        useAuthStore.getState().setVcrs(vcrs);
        return { user_id };
      }
    } catch (error) {
      // Handle the error, e.g., show an error message to the user
      toast.error('Error occurred during OAuth callback. Please try again.');
    } finally {
      useAuthStore.getState().setLoading(false);
    }
  },

  // Initiates login by redirecting to the OAuth provider's login URL
  handleLogin: async (provider) => {
    useAuthStore.getState().setLoading(true);


    const loginUrl = `${ENDPOINTS.LOGIN}/${provider}`;
    
    try {
      // Send a HEAD request to check if the login URL is accessible
      const response = await axios.get(ENDPOINTS.BASE, { timeout: 4000 });

      if (response.status === 200) {
        // If the response is successful (status 200-299), redirect the user
        window.location.href = loginUrl;
      } else {
        // If the response indicates an error, show an error message
        toast.error('There was an issue with the login URL. Please try again later.');
        useAuthStore.getState().setLoading(false);
      }
    } catch (error) {
      useAuthStore.getState().setLoading(false);
      // Handle network errors or other issues
      toast.error('There was an issue with the login process. Please try again later.');
    }
    // No need to stop the loader only when error accors because the login flow completes only when the callback ends.
    // useAuthStore.getState().setLoading(false);
  },

  getUser: async (provider) => {
    const state = useAuthStore.getState(); // Access the store state directly
    state.setLoading(true);

    const user = state.user;

    try {
      const { data } = await api.get(`${ENDPOINTS.GET_USER}/${user}`); // Using the constant
      return data;
    }catch(error){
    }finally {
      state.setLoading(false);
    }
  },

  handleLogout: async () => {
    const state = useAuthStore.getState();
    state.setLoading(true);
    
    try {
      // Optional: Try to call logout endpoint
      await api.get(ENDPOINTS.LOGOUT).catch(() => {
        // Silently handle endpoint failure
        console.warn('Logout endpoint failed');
      });
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      // Force clear authentication state
      state.forceLogout();
      
      // Optional: Show logout message
      toast.success('Logged out successfully');
    }
  },

  // Fetches all repositories
  getAllRepositories: async () => {
    const state = useAuthStore.getState(); // Access the store state directly
    state.setLoading(true);
    const vcrs = state.vcrs;
    const user = state.user;

    try {
      const { data } = await api.get(`${ENDPOINTS.GET_REPOSITORIES}/${vcrs}/${user}`); // Using the constant
      return data;
    } finally {
      state.setLoading(false);
    }
  },

  // Fetches repositories stucture
  getRepositoryStructure: async (repoId, branchName = "main") => {
    const state = useAuthStore.getState();
    state.setLoading(true);
    const vcrs = state.vcrs;
    const user = state.user;
  
    try {
      const { data } = await api.post(`${ENDPOINTS.REPOSITORY_STRUCTURE}/${vcrs}/${user}/${repoId}`, {
        'branch_name': branchName  // Explicitly send branch name
      });
      return data;
    } catch (error) {
      throw error;
    } finally {
      state.setLoading(false);
    }
  },

  // Add a new method to fetch branches for a repository
  getRepositoryBranches: async (repoId) => {
    const state = useAuthStore.getState();
    state.setLoading(true);
    const vcrs = state.vcrs;
    const user = state.user;
  
    try {
      const { data } = await api.get(`${ENDPOINTS.REPOSITORY_BRANCHES}/${user}/${vcrs}/${repoId}`);
      return data;
    } finally {
      state.setLoading(false);
    }
  },

  getIgnoredFiles: async (repoId) => {
    const state = useAuthStore.getState();
    state.setLoading(true);
    const user = state.user;
  
    try {
      const { data } = await api.get(`${ENDPOINTS.GET_IGNORED_FILES}/${user}/${repoId}`);
      return data;
    } catch (error) {
      console.error('Failed to fetch ignored files:', error);
      throw error;
    } finally {
      state.setLoading(false);
    }
  },

  addIgnoredFiles: async (repoId, files) => {
    const state = useAuthStore.getState();
    state.setLoading(true);
    const user = state.user;  
    try {
        // Organize files by type and only include non-empty arrays
        const payload = {};
        if (files.repositoryFiles?.length > 0) {
            payload.repositoryFiles = files.repositoryFiles;
        }
        if (files.localFiles?.length > 0) {
            payload.localFiles = files.localFiles;
        }

        // Only make the API call if there are files to process
        if (Object.keys(payload).length === 0) {
            throw new Error('No files selected');
        }

        const { data } = await api.post(
            `${ENDPOINTS.ADD_IGNORED_FILES}/${user}/${repoId}`,
            payload
        );
        console.log("Added ignored files", data)
        return data;
    } finally {
        state.setLoading(false);
    }
  },

  removeIgnoredFiles: async (repoId, files) => {
    const state = useAuthStore.getState();
    state.setLoading(true);
    const user = state.user;
    
    try {
        // Organize files by type and only include non-empty arrays
        const payload = {};
        if (files.repositoryFiles?.length > 0) {
            payload.repositoryFiles = files.repositoryFiles;
        }
        if (files.localFiles?.length > 0) {
            payload.localFiles = files.localFiles;
        }

        // Only make the API call if there are files to process
        if (Object.keys(payload).length === 0) {
            throw new Error('No files selected');
        }

        const { data } = await api.post(
            `${ENDPOINTS.REMOVE_IGNORED_FILES}/${user}/${repoId}`,
            payload
        );
        console.log("Removed ignored files", data)
        return data;
    } catch (error) {
        console.error('Failed to remove ignored files:', error);
        throw error;
    } finally {
        state.setLoading(false);
    }
  },

  setBusinessLogic: async ({ id, businessLogic }) => {  // Destructure what we need from the repo parameter
    const state = useAuthStore.getState();
    state.setLoading(true);
    const user = state.user;
    try {
      const { data } = await api.post(`${ENDPOINTS.ADD_BUSINESS_LOGIC}/${id}/${user}`, {
        'BusinessLogic' : businessLogic
      });
      return data;
    } finally {
      state.setLoading(false);
    }
  },

  deleteBusinessLogic: async ({ id }) => {  // Destructure what we need from the repo parameter
    const state = useAuthStore.getState();
    state.setLoading(true);
    const user = state.user;
    try {
      const { data } = await api.delete(`${ENDPOINTS.DELETE_BUSINESS_LOGIC}/${id}/${user}`);
      return data;
    } finally {
      state.setLoading(false);
    }
  },

  // Toggles the vulnerability check for a specific repository
  toggleVulnerabilityCheck: async ({ repoId, enabled }) => {  // Change to accept an object
    useAuthStore.getState().setLoading(true);
    const state = useAuthStore.getState();
    const user = state.user;
    try {
      const { data } = await api.post(`${ENDPOINTS.TOGGLE_VULNERABILITY_CHECK}/${repoId}/${user}`, {
        enabled: enabled  // Send the enabled state in the request body
      });
      return data;
    } catch (error) {
      throw error;
    } finally {
      useAuthStore.getState().setLoading(false);
    }
  },

  // Marks a specific repository
  markRepository: async (repoId) => {
    const state = useAuthStore.getState(); // Access the store state directly

    useAuthStore.getState().setLoading(true);
    const vcrs = state.vcrs;
    const user = state.user;
    try {
      const { data } = await api.post(`${ENDPOINTS.MARK_REPOSITORY}/${vcrs}/${user}/${repoId}`);
      return data; // Return the API response
    } catch (error) {
      throw error; // Re-throw the error for the caller to handle
    } finally {
      useAuthStore.getState().setLoading(false);
    }
  },

  // Unmarks a specific repository
  unMarkRepository: async (repoId) => {
    const state = useAuthStore.getState(); // Access the store state directly

    useAuthStore.getState().setLoading(true);
    const vcrs = state.vcrs;
    const user = state.user;
    try {
      const { data } = await api.delete(`${ENDPOINTS.UNMARK_REPOSITORY}/${vcrs}/${user}/${repoId}`);
      return data; // Return the API response
    } catch (error) {
      throw error; // Re-throw the error for the caller to handle
    } finally {
      useAuthStore.getState().setLoading(false);
    }
  },


  // Selects a model for a specific repository
  updateModel: async ( model ) => {
    const state = useAuthStore.getState(); // Access the store state directly
    useAuthStore.getState().setLoading(true);
    const user = state.user;
    console.log(model)
    try {
      const { data } = await api.post(`${ENDPOINTS.UPDATE_MODEL}/${user}`, {"model": model}); // Using the constant
      return data;
    } catch (error) {
      throw error;
    } finally {
      useAuthStore.getState().setLoading(false);
    }
  },

  // Selects a model for a specific repository
  getModel: async () => {
    useAuthStore.getState().setLoading(true);
    try {
      const { data } = await api.get(`${ENDPOINTS.GET_MODEL}`); // Using the constant
      return data;
    } catch (error) {
      throw error;
    } finally {
      useAuthStore.getState().setLoading(false);
    }
  },

  addRepository: async () => {
    try {
        // Open in the same window, allowing backend redirect
        window.location.assign(ENDPOINTS.ADD_REPOSITORY);
    } catch (error) {
        console.error('Add repository error:', error);
        toast.error('Failed to add repository', {
            description: error.message || 'Please try again later.'
        });
        throw error;
    }
  },

};

// React Query hook for OAuth callback handling
export const useOAuthCallback = () => {
  return apiService.handleOAuthCallback;
};

export const useGetUser = () => {
  const user = useAuthStore((state) => state.user);
  return useQuery({
    queryKey: ['user', user],
    queryFn: apiService.getUser,
    enabled: !!user,
    onError: (error) => {
      // Check if it's a network error or server unreachable
      if (!error.response) {
        errorHandler.showError('Server is unreachable', {
          description: 'Unable to fetch user information. Please check your network connection.'
        });
      }
    }
  });
};

// React Query hook for toggling the vulnerability check
export const useToggleVulnerabilityCheck = () => {
  return useMutation({
    mutationFn: (params) => apiService.toggleVulnerabilityCheck(params),
    onSuccess: () => {
      // Optionally add success handling
    },
    onError: (error) => {
      toast.error('Failed to toggle vulnerability check', {
        description: error.message
      });
    }
  });
};

// React Query hook for fetching all repositories
export const useRepositories = () => {
  const user = useAuthStore((state) => state.user);
  const vcrs = useAuthStore((state) => state.vcrs)

  return useQuery({
    queryKey: ['repositories', user, vcrs],
    queryFn: apiService.getAllRepositories,
    enabled: !!user,
    onError: (error) => {
      // Check if it's a network error or server unreachable
      if (!error.response) {
        errorHandler.showError('Server is unreachable', {
          description: 'Unable to fetch repositories. Please check your network connection.'
        });
      }
    }
  });
};

// React Query hook for marking a repository
export const useMarkRepository = () => {
  return useMutation({
    mutationFn: apiService.markRepository,
  });
};

// React Query hook for unmarking a repository
export const useUnMarkRepository = () => {
  return useMutation({
    mutationFn: (model) => apiService.unMarkRepository(model),
  });
};

// React Query hook for setting business logic for a repository
export const useSetBusinessLogic = () => {
  return useMutation({
    mutationFn: (repo, businessLogic) => apiService.setBusinessLogic(repo, businessLogic),
  });
};

// React Query hook for deleting business logic for a repository
export const useDeleteBusinessLogic = () => {
  return useMutation({
    mutationFn: (repo) => apiService.deleteBusinessLogic(repo),
    onError: (error) => {
      toast.error(`Failed to delete business logic: ${error.message}`);
    }
  });
};

// React Query hook for selecting a model for a repository
export const useGetModels = () => {
  return useQuery({
    queryKey: ['models'],
    queryFn: apiService.getModel,
  });
};

// React Query hook for selecting a model for a repository
export const useSelectModel = () => {
  return useMutation({
    mutationFn: apiService.selectModel,
  });
};

// React Query hook for handling login
export const useHandleLogin = () => {
  return useMutation({
    mutationFn: (provider) => {
      apiService.handleLogin(provider);
    }
  });
};

// React Query hook for handling logout
export const useLogout = () => {
  return apiService.handleLogout
};

// React Query hook for getting repository structure
// React Query hook for get repository stucture of repository
export const useGetRepositoryStucture = (repoId, branchName = "master") => {
  return useQuery({
    queryKey: ['repositoryStructure', repoId, branchName],
    queryFn: () => apiService.getRepositoryStructure(repoId, branchName),
    enabled: !!repoId,
    // Add caching configuration
    staleTime: 300000, // Data becomes stale after 5 minutes
    cacheTime: 3600000, // Cache persists for 1 hour
    // Disable automatic refetching
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retryOnMount: false
  });
}

export const useGetRepositoryBranches = (repoId) => {
  return useQuery({
    queryKey: ['repositoryBranches', repoId],
    queryFn: () => apiService.getRepositoryBranches(repoId),
    enabled: !!repoId,
  });
};

export const useGetIgnoredFiles = (repoId) => {
  return useQuery({
    queryKey: ['ignoredFiles', repoId],
    queryFn: () => apiService.getIgnoredFiles(repoId),
    enabled: !!repoId,
    // Add caching configuration
    staleTime: 0, // Data becomes stale after 30 seconds
    cacheTime: 0, // Cache persists for 1 hour
    // Disable automatic refetching
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retryOnMount: false
  });
};

export const useAddIgnoredFiles = () => {
    return useMutation({
        mutationFn: ({ repoId, repositoryFiles, localFiles }) => {
            // Filter out any duplicate files
            const uniqueRepoFiles = [...new Set(repositoryFiles)];
            const uniqueLocalFiles = [...new Set(localFiles)];
            
            // Only make the API call if there are files to process
            if (uniqueRepoFiles.length === 0 && uniqueLocalFiles.length === 0) {
                return Promise.resolve({ success: true, updates: {}, repository: {} });
            }

            return apiService.addIgnoredFiles(repoId, {
                repositoryFiles: uniqueRepoFiles,
                localFiles: uniqueLocalFiles
            });
        },
        onError: (error) => {
            const { title, description } = getErrorMessage(error);
            toast.error(title, { description });
        },
        retry: false,  // Disable retries for this mutation
        onMutate: () => {
            // Optionally add loading state here
            useAuthStore.getState().setLoading(true);
        },
        onSettled: () => {
            // Always clean up loading state
            useAuthStore.getState().setLoading(false);
        }
    });
};

export const useRemoveIgnoredFiles = () => {
    
    return useMutation({
        mutationFn: ({ repoId, repositoryFiles, localFiles }) => {
            return apiService.removeIgnoredFiles(repoId, {
                repositoryFiles,
                localFiles
            });
        },
        onError: (error) => {
            const { title, description } = getErrorMessage(error);
            toast.error(title, { description });
        },
        retry: (failureCount, error) => {
            return !isNetworkError(error) && failureCount < 3;
        },
        onSettled: () => {
            useAuthStore.getState().setLoading(false);
        }
    });
};

// React Query hook for updating model
export const useUpdateModel = () => {
  return useMutation({
    mutationFn: apiService.updateModel,
  });
};

export const useAddRepository = () => {
  return useMutation({
    mutationFn: apiService.addRepository,
    onSuccess: () => {
      // toast.success('GitHub Repository added successfully!');
    },
    onError: (error) => {
      toast.error('Failed to add repository', {
        description: error.message
      });
    }
  });
};
