import React, { useState } from 'react';
import { Github, GitlabIcon as GitlabLogo, Building2, GitPullRequest, Sparkles } from 'lucide-react';
import { Button } from "../components/ui/button";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "../components/ui/card";
import { useHandleLogin } from '../services/api-service';
import { motion } from 'framer-motion';

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2
    }
  }
};

const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 24
    }
  },
  hover: {
    scale: 1.02,
    transition: { duration: 0.2 }
  }
};

const LoginButton = ({ icon: Icon, provider, label, isLoading, onClick }) => (
  <motion.div
    variants={itemVariants}
    whileHover="hover"
    whileTap={{ scale: 0.98 }}
    initial="hidden"
    animate="visible"
  >
    <Button
      variant="outline"
      className={`
        w-full h-14 relative group overflow-hidden
        ${isLoading !== null ? 'opacity-50 cursor-not-allowed' : ''}
        border border-gray-200 dark:border-gray-700
        hover:border-primary/50 dark:hover:border-primary/50
        bg-white dark:bg-gray-800
      `}
      onClick={() => onClick(provider)}
      disabled={isLoading !== null}
    >
      <div className="absolute inset-0 bg-gradient-to-r from-primary/5 via-primary/10 to-blue-600/5 opacity-0 group-hover:opacity-100 transition-opacity" />
      <div className="relative flex items-center justify-center space-x-3">
        <motion.div
          animate={isLoading === provider ? { 
            rotate: 360,
            transition: { 
              repeat: Infinity, 
              duration: 1, 
              ease: "linear" 
            } 
          } : {}}
          className={`p-1.5 rounded-md ${
            isLoading === provider ? 'bg-primary/10' : 'bg-transparent'
          }`}
        >
          <Icon className={`h-5 w-5 ${
            isLoading === provider ? 'text-primary animate-pulse' : ''
          }`} />
        </motion.div>
        <span className="font-medium">
          {isLoading === provider ? 'Connecting...' : label}
        </span>
      </div>
    </Button>
  </motion.div>
);

const FloatingParticle = ({ delay }) => (
  <motion.div
    initial={{ y: 0, opacity: 0 }}
    animate={{ 
      y: -100,
      opacity: [0, 1, 0],
    }}
    transition={{ 
      duration: 2,
      delay,
      repeat: Infinity,
      ease: "easeOut"
    }}
    className="absolute"
  >
    <Sparkles className="w-3 h-3 text-primary/40" />
  </motion.div>
);

export function OauthLogin() {
  const [isLoading, setIsLoading] = useState(null);
  const { mutate: handleLogin } = useHandleLogin();

  const handleChange = (provider) => {
    setIsLoading(provider);
    handleLogin(provider);
    setTimeout(() => setIsLoading(null), 2000);
  };

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className="relative min-h-screen bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800 overflow-hidden"
    >
      {/* Animated Background Elements */}
      <div className="absolute inset-0">
        <motion.div 
          animate={{ 
            scale: [1, 1.2, 1],
            rotate: [0, 360],
          }}
          transition={{ 
            duration: 20,
            repeat: Infinity,
            ease: "linear"
          }}
          className="absolute top-1/4 left-1/4 w-96 h-96 bg-primary/5 rounded-full blur-3xl"
        />
        <motion.div 
          animate={{ 
            scale: [1.2, 1, 1.2],
            rotate: [0, -360],
          }}
          transition={{ 
            duration: 25,
            repeat: Infinity,
            ease: "linear"
          }}
          className="absolute bottom-1/4 right-1/4 w-96 h-96 bg-blue-600/5 rounded-full blur-3xl"
        />
      </div>

      {/* Floating Particles */}
      {[...Array(6)].map((_, i) => (
        <FloatingParticle key={i} delay={i * 0.5} />
      ))}

      <div className="relative flex items-center justify-center min-h-screen p-6">
        <motion.div 
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="w-full max-w-md space-y-8"
        >
          {/* Logo Animation */}
          <motion.div 
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ type: "spring", stiffness: 260, damping: 20 }}
            className="flex flex-col items-center space-y-4"
          >
            <motion.div 
              animate={{
                scale: [1, 1.1, 1],
                rotate: [0, 5, -5, 0]
              }}
              transition={{
                duration: 4,
                repeat: Infinity,
                ease: "easeInOut"
              }}
              className="relative p-4 bg-gradient-to-br from-primary/10 to-blue-600/10 rounded-2xl backdrop-blur-xl"
            >
              <div className="absolute inset-0 bg-white/40 dark:bg-gray-800/40 rounded-2xl" />
              <GitPullRequest className="relative w-14 h-14 text-primary" />
            </motion.div>
          </motion.div>

          {/* Login Card */}
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
          >
            <Card className="bg-white/80 dark:bg-gray-800/80 backdrop-blur-xl border-primary/10">
              <CardHeader className="space-y-2 text-center pb-6">
                <CardTitle className="text-3xl font-bold">
                  <span className="bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent">
                    Welcome to ACRS
                  </span>
                </CardTitle>
                <CardDescription className="text-gray-600 dark:text-gray-300">
                  Choose your preferred login method
                </CardDescription>
              </CardHeader>

              <CardContent>
                <motion.div 
                  variants={containerVariants}
                  initial="hidden"
                  animate="visible"
                  className="space-y-4"
                >
                  <LoginButton
                    icon={Github}
                    provider="github"
                    label="Continue with GitHub"
                    isLoading={isLoading}
                    onClick={handleChange}
                  />
                  
                  <LoginButton
                    icon={GitlabLogo}
                    provider="gitlab"
                    label="Continue with GitLab"
                    isLoading={isLoading}
                    onClick={handleChange}
                  />
                  
                  <LoginButton
                    icon={Building2}
                    provider="qburst"
                    label="Continue with Code.Qburst"
                    isLoading={isLoading}
                    onClick={handleChange}
                  />
                </motion.div>
              </CardContent>
            </Card>
          </motion.div>

          {/* Footer Text */}
          <motion.p 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
            className="text-center text-sm text-gray-500 dark:text-gray-400"
          >
            By continuing, you agree to our{' '}
            <a href="/terms" className="text-primary hover:underline">Terms of Service</a>
            {' '}and{' '}
            <a href="/privacy" className="text-primary hover:underline">Privacy Policy</a>
          </motion.p>
        </motion.div>
      </div>
    </motion.div>
  );
}