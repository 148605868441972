import React from 'react';
import { AlertCircle, Loader2 } from 'lucide-react';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogDescription,
    DialogFooter,
} from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";

const FileExistsModal = ({ 
    isOpen, 
    onClose, 
    files, 
    onConfirm, 
    isLoading = false,
    title = "Files Found in Repository",
    description = "The following local file(s) exist in the current branch. Would you like to select them in the repository instead?"
}) => {
    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[500px]">
                <DialogHeader>
                    <DialogTitle className="flex items-center gap-2">
                        <AlertCircle className="h-5 w-5 text-primary" />
                        {title}
                    </DialogTitle>
                    <DialogDescription>
                        {description}
                    </DialogDescription>
                </DialogHeader>

                <div className="max-h-[200px] overflow-y-auto my-4">
                    <div className="space-y-2">
                        {files.map((file, index) => (
                            <div 
                                key={index}
                                className="p-2 rounded-md bg-gray-50 dark:bg-gray-800 text-sm"
                            >
                                {file}
                            </div>
                        ))}
                    </div>
                </div>

                <DialogFooter className="gap-2">
                    <Button
                        variant="outline"
                        onClick={onClose}
                        disabled={isLoading}
                    >
                        Keep as Local Files
                    </Button>
                    <Button
                        onClick={onConfirm}
                        disabled={isLoading}
                        className="relative"
                    >
                        {isLoading && (
                            <Loader2 className="h-4 w-4 animate-spin mr-2" />
                        )}
                        {isLoading ? 'Processing...' : 'Move to Repository'}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default FileExistsModal; 