import React, { createContext, useContext, useState, useEffect, useCallback, useRef } from 'react';

const FileSelectionContext = createContext({
  selectedFiles: [],
  selectedFolders: [],
  deselectedFiles: [],
  deselectedFolders: [],
  toggleFileSelection: () => {},
  toggleFolderSelection: () => {},
  isFileSelected: () => false,
  isFolderSelected: () => false,
  isPreviouslySelected: () => false,
  isFolderFullySelected: () => false
});

export const useFileSelection = () => useContext(FileSelectionContext);

export const FileSelectionProvider = ({ 
    children, 
    onSelectionChange, 
    initialSelectedFiles = [],
    initialSelectedFolders = [],
    treeData,
    backendState
}) => {
    const [selectedFiles, setSelectedFiles] = useState(new Set(initialSelectedFiles));
    const [selectedFolders, setSelectedFolders] = useState(new Set(initialSelectedFolders));
    const [deselectedFiles, setDeselectedFiles] = useState(new Set());
    const [deselectedFolders, setDeselectedFolders] = useState(new Set());

    // Initialize selections from backend state when component mounts or backendState.repository changes
    useEffect(() => {
        if (backendState?.repository) {
            setSelectedFiles(prev => {
                const newSet = new Set(prev);
                backendState.repository.forEach(file => {
                    newSet.add(file);
                });
                return newSet;
            });
        }
    }, [backendState?.repository]);

    // Update selections when initialSelectedFiles/Folders change (e.g., branch switch)
    useEffect(() => {
        setSelectedFiles(new Set(initialSelectedFiles));
        setSelectedFolders(new Set(initialSelectedFolders));
        setDeselectedFiles(new Set());
        setDeselectedFolders(new Set());
    }, [initialSelectedFiles, initialSelectedFolders]);

    const findAllFilesInFolder = useCallback((folderPath) => {
        const files = [];

        // Helper function to find the folder node
        const findFolderNode = (path) => {
            const stack = [...(treeData || [])];
            while (stack.length) {
                const current = stack.pop();
                if (current.path === path) return current;
                if (current.children) {
                    stack.push(...current.children);
                }
            }
            return null;
        };

        // Find the specific folder node first
        const folderNode = findFolderNode(folderPath);
        if (folderNode && folderNode.children) {
            // Start search from the specific folder's children
            const stack = [...folderNode.children];
            while (stack.length) {
                const current = stack.pop();
                if (current.type === 'blob') {
                    files.push(current.path);
                } else if (current.type === 'tree' && current.children) {
                    stack.push(...current.children);
                }
            }
        }

        return files;
    }, [treeData]);

    // Notify parent component of selection changes
    useEffect(() => {
        onSelectionChange?.({
            selectedFiles: Array.from(selectedFiles),
            selectedFolders: Array.from(selectedFolders),
            deselectedFiles: Array.from(deselectedFiles),
            deselectedFolders: Array.from(deselectedFolders)
        });
    }, [selectedFiles, selectedFolders, deselectedFiles, deselectedFolders, onSelectionChange]);

    const isFileSelected = useCallback((filePath) => {
        return selectedFiles.has(filePath);
    }, [selectedFiles]);

    const isFolderSelected = useCallback((folderPath) => {
        return selectedFolders.has(folderPath);
    }, [selectedFolders]);

    const isFolderFullySelected = useCallback((folderPath) => {
        const folderFiles = findAllFilesInFolder(folderPath);
        return folderFiles.length > 0 && folderFiles.every(file => 
            selectedFiles.has(file) && !deselectedFiles.has(file)
        );
    }, [selectedFiles, deselectedFiles, findAllFilesInFolder]);

    const toggleFileSelection = useCallback((filePath) => {
        setSelectedFiles(prev => {
            const newSet = new Set(prev);
            if (newSet.has(filePath)) {
                newSet.delete(filePath);
                if (initialSelectedFiles.includes(filePath)) {
                    setDeselectedFiles(prev => new Set([...prev, filePath]));
                }
            } else {
                newSet.add(filePath);
                setDeselectedFiles(prev => {
                    const newDeselected = new Set(prev);
                    newDeselected.delete(filePath);
                    return newDeselected;
                });
            }
            return newSet;
        });
    }, [initialSelectedFiles]);

    const toggleFolderSelection = useCallback((folderPath) => {
        const folderFiles = findAllFilesInFolder(folderPath);
        const isFullySelected = isFolderFullySelected(folderPath);
        const isSelected = selectedFolders.has(folderPath);

        if (isFullySelected || isSelected) {
            // Deselection logic
            setSelectedFolders(prev => {
                const newSet = new Set(prev);
                newSet.delete(folderPath);
                return newSet;
            });

            setSelectedFiles(prev => {
                const newSet = new Set(prev);
                folderFiles.forEach(file => newSet.delete(file));
                return newSet;
            });

            if (initialSelectedFolders.includes(folderPath)) {
                setDeselectedFolders(prev => new Set([...prev, folderPath]));
            }
            folderFiles.forEach(file => {
                if (initialSelectedFiles.includes(file)) {
                    setDeselectedFiles(prev => new Set([...prev, file]));
                }
            });
        } else {
            // Selection logic
            setSelectedFolders(prev => {
                const newSet = new Set(prev);
                newSet.add(folderPath);
                return newSet;
            });
            
            setSelectedFiles(prev => {
                const newSet = new Set(prev);
                folderFiles.forEach(file => newSet.add(file));
                return newSet;
            });

            setDeselectedFolders(prev => {
                const newSet = new Set(prev);
                newSet.delete(folderPath);
                return newSet;
            });
            setDeselectedFiles(prev => {
                const newSet = new Set(prev);
                folderFiles.forEach(file => newSet.delete(file));
                return newSet;
            });
        }
    }, [selectedFolders, findAllFilesInFolder, initialSelectedFiles, initialSelectedFolders, isFolderFullySelected]);

    const prevSelectionRef = useRef({
        selectedFiles: [],
        selectedFolders: [],
        deselectedFiles: [],
        deselectedFolders: []
    });

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            const finalSelectedFiles = Array.from(selectedFiles);
            const finalSelectedFolders = Array.from(selectedFolders);

            const prev = prevSelectionRef.current;
            const hasChanged = 
                JSON.stringify(finalSelectedFiles) !== JSON.stringify(prev.selectedFiles) ||
                JSON.stringify(finalSelectedFolders) !== JSON.stringify(prev.selectedFolders) ||
                JSON.stringify(Array.from(deselectedFiles)) !== JSON.stringify(prev.deselectedFiles) ||
                JSON.stringify(Array.from(deselectedFolders)) !== JSON.stringify(prev.deselectedFolders);

            if (hasChanged) {
                prevSelectionRef.current = {
                    selectedFiles: finalSelectedFiles,
                    selectedFolders: finalSelectedFolders,
                    deselectedFiles: Array.from(deselectedFiles),
                    deselectedFolders: Array.from(deselectedFolders)
                };

                onSelectionChange?.({
                    selectedFiles: finalSelectedFiles,
                    selectedFolders: finalSelectedFolders,
                    deselectedFiles: Array.from(deselectedFiles),
                    deselectedFolders: Array.from(deselectedFolders)
                });
            }
        }, 100);

        return () => clearTimeout(timeoutId);
    }, [selectedFiles, selectedFolders, deselectedFiles, deselectedFolders, onSelectionChange]);

    const isPreviouslySelected = useCallback((path) => {
        return initialSelectedFiles.includes(path) || initialSelectedFolders.includes(path);
    }, [initialSelectedFiles, initialSelectedFolders]);

    return (
        <FileSelectionContext.Provider value={{
            selectedFiles: Array.from(selectedFiles),
            selectedFolders: Array.from(selectedFolders),
            deselectedFiles: Array.from(deselectedFiles),
            deselectedFolders: Array.from(deselectedFolders),
            toggleFileSelection,
            toggleFolderSelection,
            isFileSelected,
            isFolderSelected,
            isFolderFullySelected,
            isPreviouslySelected,
            backendState,
            findAllFilesInFolder
        }}>
            {children}
        </FileSelectionContext.Provider>
    );
};