import React from 'react';
import { Button } from '../../ui/button';
import { Badge } from '../../ui/badge';
import { Plus, X, Loader2 } from 'lucide-react';

const UpdateButton = ({
    isAddDisabled,
    isRemoveDisabled,
    handleIgnoredFiles,
    currentSelections,
    localFileChanges,
    isProcessing
}) => {
    // Calculate counts for tooltips
    const newRepoFiles = currentSelections.newlySelectedFiles.length;
    const newLocalFiles = localFileChanges.added.size;
    const removedRepoFiles = currentSelections.deselectedFiles.length;
    const removedLocalFiles = localFileChanges.removed.size;

    return (
        <div className="flex items-center space-x-4">
            <div className="relative group">
                <Button
                    onClick={() => handleIgnoredFiles("add")}
                    disabled={isAddDisabled() || isProcessing}
                    className={`
                        flex items-center space-x-2 px-4 py-2
                        ${isAddDisabled() || isProcessing
                            ? 'bg-gray-300 cursor-not-allowed' 
                            : 'bg-green-600 hover:bg-green-700'}
                        text-white transition-all duration-200
                    `}
                >
                    {isProcessing ? (
                        <Loader2 className="h-4 w-4 animate-spin mr-2" />
                    ) : (
                        <Plus className="h-4 w-4" />
                    )}
                    <span>{isProcessing ? 'Adding...' : 'Add Files'}</span>
                    {!isProcessing && (newRepoFiles > 0 || newLocalFiles > 0) && (
                        <Badge className="ml-2 bg-green-500">
                            {newRepoFiles + newLocalFiles}
                        </Badge>
                    )}
                </Button>
            </div>

            <div className="relative group">
                <Button
                    onClick={() => handleIgnoredFiles("remove")}
                    disabled={isRemoveDisabled() || isProcessing}
                    className={`
                        flex items-center space-x-2 px-4 py-2
                        ${isRemoveDisabled() || isProcessing
                            ? 'bg-gray-300 cursor-not-allowed' 
                            : 'bg-red-600 hover:bg-red-700'}
                        text-white transition-all duration-200
                    `}
                >
                    {isProcessing ? (
                        <Loader2 className="h-4 w-4 animate-spin mr-2" />
                    ) : (
                        <X className="h-4 w-4" />
                    )}
                    <span>{isProcessing ? 'Removing...' : 'Remove Files'}</span>
                    {!isProcessing && (removedRepoFiles > 0 || removedLocalFiles > 0) && (
                        <Badge className="ml-2 bg-red-500">
                            {removedRepoFiles + removedLocalFiles}
                        </Badge>
                    )}
                </Button>
            </div>
        </div>
    );
};

export default UpdateButton; 