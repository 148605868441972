export const isNetworkError = (error) => {
    return (
        error.message === 'Network Error' ||
        error.code === 'ERR_NETWORK' ||
        error.code === 'ECONNABORTED' ||
        !error.response
    );
};

export const getErrorMessage = (error) => {
    if (isNetworkError(error)) {
        return {
            title: 'Unable to Connect to Server',
            description: 'Please check your internet connection and try again.'
        };
    }
    
    return {
        title: 'Operation Failed',
        description: error.message || 'An unexpected error occurred'
    };
}; 