import React, { useState, memo } from 'react';
import { ChevronRight, Folder, X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { cn } from '../../../lib/utils';
import { useFileSelection } from '../context/FileSelectionContext';
import { getFileIcon } from '../utils/fileIcons';

const MemoizedXIcon = memo(() => (
  <motion.div
    initial={{ opacity: 0, scale: 0.8 }}
    animate={{ opacity: 1, scale: 1 }}
    exit={{ opacity: 0, scale: 0.8 }}
    className="absolute right-2"
  >
    <X className="h-4 w-4 text-red-500 dark:text-red-400 transition-opacity" />
  </motion.div>
));

const FileTreeItem = ({ item, depth = 0 }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { 
      toggleFileSelection, 
      toggleFolderSelection, 
      isFileSelected,
      isFolderSelected,
      isFolderFullySelected,
      isPreviouslySelected,
      backendState,
      findAllFilesInFolder
    } = useFileSelection();
  
    if (!item || typeof item !== 'object') {
      console.warn('Invalid item in FileTreeItem:', item);
      return null;
    }
  
    const isItemInBackendState = () => {
      if (item.type === 'blob') {
        return backendState.repository.has(item.path);
      } else if (item.type === 'tree') {
        const folderFiles = findAllFilesInFolder(item.path);
        return folderFiles.some(file => backendState.repository.has(file));
      }
      return false;
    };
    
  
    const toggleExpand = (e) => {
      e.stopPropagation();
      if (item.type === 'tree') {
        setIsExpanded(!isExpanded);
      }
    };
  
    const handleItemSelect = (e) => {
      e.stopPropagation();
      if (e.target.closest('.expand-icon')) return;
      
      if (item.type === 'blob') {
        toggleFileSelection(item.path);
      } else if (item.type === 'tree') {
        toggleFolderSelection(item.path);
      }
    };
  
    const isItemSelected = () => {
      if (item.type === 'blob') {
        return isFileSelected(item.path);
      } else if (item.type === 'tree') {
        return isFolderSelected(item.path) || isFolderFullySelected(item.path);
      }
      return false;
    };
  
    const getItemClassNames = () => {
      const baseClasses = [
        "group flex items-center",
        "cursor-pointer",
        "transition-all duration-200",
        "relative",
        "hover:bg-gradient-to-r hover:from-red-500/5 hover:to-red-600/5",
        "px-3 py-2 rounded-md"
      ];
    
      const selectedClasses = [
        "bg-gradient-to-r from-red-500/10 to-red-600/10",
        "text-red-600 dark:text-red-400",
        "font-semibold"
      ];
    
      return cn(
        baseClasses,
        (isItemSelected() || isPreviouslySelected(item.path)) && selectedClasses
      );
    };
  
    return (
      <div>
        <motion.div 
          className={getItemClassNames()}
          style={{ paddingLeft: `${depth * 16 + 8}px` }}
          onClick={handleItemSelect}
          whileHover={{ scale: 1.01 }}
          transition={{ duration: 0.2 }}
        >
          <div className="flex items-center flex-1 min-w-0">
            <div 
              className="expand-icon mr-1" 
              onClick={toggleExpand}
            >
              {item.type === 'tree' && (
                <motion.div
                  initial={false}
                  animate={{ rotate: isExpanded ? 90 : 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <ChevronRight 
                    className={cn(
                      "h-4 w-4",
                      (isItemSelected() || isPreviouslySelected(item.path)) && 
                        "text-red-500 dark:text-red-400"
                    )} 
                  />
                </motion.div>
              )}
            </div>

            {item.type === 'tree' ? (
              <Folder className="h-4 w-4 text-amber-500 mr-2" />
            ) : (
              <span className="mr-2">{getFileIcon(item.path)}</span>
            )}

            <span className="truncate text-sm">
              {item.name || item.path.split('/').pop()}
            </span>
          </div>

          {isItemInBackendState() && isItemSelected() && <MemoizedXIcon />}
        </motion.div>

        {item.type === 'tree' && item.children && (
          <AnimatePresence initial={false}>
            {isExpanded && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.2 }}
                style={{ overflow: 'hidden' }}
              >
                {Array.isArray(item.children) && item.children.map((child, index) => (
                  <FileTreeItem
                    key={`${child.path}-${index}`}
                    item={child}
                    depth={depth + 1}
                  />
                ))}
              </motion.div>
            )}
          </AnimatePresence>
        )}
      </div>
    );
  };

export default memo(FileTreeItem);
