import React from 'react';
import { motion } from 'framer-motion';
import { Loader2 } from 'lucide-react';
import FileTreeItem from './FileTreeItem';

const FileTreeView = ({ data }) => {
    if (!data) {
      return (
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="flex items-center justify-center h-full py-12"
        >
          <div className="flex flex-col items-center space-y-4">
            <div className="relative">
              <div className="absolute inset-0 blur-xl bg-gradient-to-r from-primary/30 to-blue-600/30 animate-pulse" />
              <Loader2 className="h-12 w-12 animate-spin text-primary relative" />
            </div>
            <div className="text-gray-600 dark:text-gray-300 font-medium">
              Loading repository structure...
            </div>
          </div>
        </motion.div>
      );
    }
  
    return (
      <div className="
        file-tree-view 
        bg-white/50 dark:bg-gray-800/50 
        backdrop-blur-xl 
        rounded-lg 
        border border-gray-200 dark:border-gray-700 
        shadow-sm
        relative
        overflow-hidden
      ">
        <div className="absolute inset-0 bg-gradient-to-br from-primary/5 to-blue-600/5 pointer-events-none" />
        <div className="relative">
          {data.length === 0 ? (
            <div className="text-center text-gray-500 dark:text-gray-400 py-8">
              No files found in this repository
            </div>
          ) : (
            <div className="py-2">
              {data.map((item) => (
                <FileTreeItem 
                  key={item.path} 
                  item={item} 
                />
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };

export default FileTreeView;