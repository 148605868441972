import React from 'react';
import { GitBranch, Loader2 } from 'lucide-react';
import { Badge } from '../../ui/badge';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../ui/select";

const BranchSelector = ({ 
    branches, 
    isBranchesLoading, 
    selectedBranch, 
    onBranchChange 
}) => {
    return (
        <div className="flex items-center space-x-6">
            {isBranchesLoading ? (
                <div className="flex items-center space-x-2 text-gray-500 dark:text-gray-400">
                    <Loader2 className="h-4 w-4 animate-spin" />
                    <span>Loading branches...</span>
                </div>
            ) : branches.length === 0 ? (
                <Badge variant="destructive" className="flex items-center px-3 py-1">
                    <GitBranch className="mr-2 h-4 w-4" />
                    No branches found
                </Badge>
            ) : (
                <div className="flex items-center space-x-4">
                    <div className="flex items-center">
                        <GitBranch className="h-4 w-4 text-amber-600 dark:text-amber-500 mr-2" />
                        <span className="font-medium text-sm text-gray-700 dark:text-gray-300">Branch:</span>
                    </div>
                    <Select value={selectedBranch} onValueChange={onBranchChange}>
                        <SelectTrigger className="w-[200px] h-8 text-sm bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700">
                            <SelectValue placeholder="Choose a branch" />
                        </SelectTrigger>
                        <SelectContent className="bg-white dark:bg-gray-800 border-gray-200 dark:border-gray-700">
                            {branches.map((branch) => (
                                <SelectItem 
                                    key={branch} 
                                    value={branch}
                                    className="text-gray-700 dark:text-gray-300"
                                >
                                    {branch}
                                </SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>
            )}
        </div>
    );
};

export default BranchSelector; 