export function processFileTree(data) {
    const processNode = (node, path) => {
      // Handle different possible input structures
      if (node.status === 'success' && node.data) {
        node = node.data;
      }
  
      // If node is null or undefined, return empty array
      if (!node) return [];
  
      const result = Object.entries(node)
        .filter(([key, value]) => 
          // Ensure the item is an object and has a type of 'tree' or 'blob'
          value && typeof value === 'object' && 
          (value.type === 'tree' || value.type === 'blob')
        )
        .map(([key, value]) => {
          const nodeResult = {
            type: value.type,
            path: value.path || key,
            mode: value.mode
          };
  
          // Recursively process child nodes for 'tree' type
          if (value.type === 'tree') {
            nodeResult.children = processNode(value, value.path);
          }
  
          return nodeResult;
        })
        .sort((a, b) => {
          // First, separate folders and files
          if (a.type === 'tree' && b.type === 'blob') return -1;
          if (a.type === 'blob' && b.type === 'tree') return 1;
          
          // If both are the same type, sort alphabetically
          return a.path.localeCompare(b.path);
        });
  
      return result;
    };
  
    // Call processNode with the entire data object
    return processNode(data);
  }