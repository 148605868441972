import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Card, CardDescription, CardHeader, CardTitle } from "../ui/card";
import { Button } from "../ui/button";
import { Badge } from "../ui/badge";
import { ArrowLeft } from 'lucide-react';
import { motion } from 'framer-motion';
import { toast } from 'sonner';
import RepositoryContent from './components/RepositoryContent';
import { 
    useGetRepositoryStucture, 
    useGetRepositoryBranches,
    useGetIgnoredFiles, 
    useAddIgnoredFiles, 
    useRemoveIgnoredFiles 
} from '../../services/api-service';
import { processFileTree } from '../../utils/processFileTree';
import FileExistsModal from './components/FileExistsModal';
import { flushSync } from 'react-dom';

function RepoDetailPage({ 
    repoId, 
    onBack, 
    repoName = "Repository", 
}) {
    // State management
    const [selectedBranch, setSelectedBranch] = useState();
    const [initialLocalFiles, setInitialLocalFiles] = useState(new Set());
    const [localFiles, setLocalFiles] = useState(new Set());
    const [globalSelections, setGlobalSelections] = useState({
        files: new Set(),
        folders: new Set(),
        newlySelectedFiles: new Set(),
        deselectedFiles: new Set()
    });
    const [localFileChanges, setLocalFileChanges] = useState({
        added: new Set(),
        removed: new Set(),
        existsInOtherBranches: new Map()
    });
    const [removedLocalFiles, setRemovedLocalFiles] = useState(new Set());
    const [backendState, setBackendState] = useState({
        repository: new Set(),
        local: new Set()
    });
    const [fileOrder, setFileOrder] = useState([]);
    const [localFileInput, setLocalFileInput] = useState('');
    const [modalState, setModalState] = useState({
        isOpen: false,
        files: [],
        onConfirm: () => {},
        isLoading: false
    });
    const [isProcessingFiles, setIsProcessingFiles] = useState(false);
    const [shouldProcessBranch, setShouldProcessBranch] = useState(false);
    const processingRef = useRef(false);

    // helper function
    const isFileInRepoStructure = (filePath, structure) => {
        const findFile = (items) => {
            if (!items) return false;
            
            for (const item of items) {
                if (item.type === 'blob' && item.path === filePath) {
                    return true;
                }
                if (item.type === 'tree' && item.children) {
                    if (findFile(item.children)) return true;
                }
            }
            return false;
        };
        
        return findFile(structure);
    };

    // API hooks
    const { mutateAsync: addIgnoredFiles } = useAddIgnoredFiles();
    const { mutateAsync: removeIgnoredFiles } = useRemoveIgnoredFiles();
    const { data: ignoredFilesData } = useGetIgnoredFiles(repoId);
    const { 
        data: branches = [], 
        isLoading: isBranchesLoading 
    } = useGetRepositoryBranches(repoId);
    const { 
        data: rawRepositoryStructure, 
        isLoading, 
        isError,
    } = useGetRepositoryStucture(repoId, selectedBranch || undefined);

    const repositoryStructure = rawRepositoryStructure 
        ? processFileTree(rawRepositoryStructure) 
        : null;

    // Effects
    useEffect(() => {
        if (branches && branches.length > 0) {
            setSelectedBranch(branches[0]);
            setShouldProcessBranch(true);
        }
    }, [branches]);

    useEffect(() => {
        if (ignoredFilesData) {
            setBackendState({
                repository: new Set(ignoredFilesData.repository || []),
                local: new Set(ignoredFilesData.local || [])
            });

            const apiLocalFiles = new Set(ignoredFilesData.local || []);
            setInitialLocalFiles(apiLocalFiles);
            setLocalFiles(apiLocalFiles);
            setFileOrder(ignoredFilesData.local || []);
            
            const apiRepositoryFiles = new Set(ignoredFilesData.repository || []);
            setGlobalSelections(prev => ({
                ...prev,
                files: new Set([...apiRepositoryFiles]),
                previouslyIgnoredFiles: apiRepositoryFiles
            }));
        }
    }, [ignoredFilesData]);

    // Handlers
    const handleBranchChange = (branch) => {
        setSelectedBranch(branch);
        setShouldProcessBranch(true);
    };

    const handleFileSelectionChange = useCallback(({ selectedFiles, selectedFolders = [], deselectedFiles = [], deselectedFolders = [] }) => {
        setGlobalSelections(prev => {
            const newFiles = new Set(prev.files);
            const newFolders = new Set(prev.folders);
            const newlySelected = new Set(prev.newlySelectedFiles);
            const deselected = new Set(prev.deselectedFiles);
        
            selectedFiles.forEach(file => {
                newFiles.add(file);
                if (backendState.repository.has(file)) {
                    deselected.delete(file);
                } else {
                    newlySelected.add(file);
                }
            });
        
            deselectedFiles.forEach(file => {
                newFiles.delete(file);
                if (backendState.repository.has(file)) {
                    deselected.add(file);
                }
                newlySelected.delete(file);
            });
        
            selectedFolders.forEach(folder => {
                newFolders.add(folder);
                newFiles.delete(folder);
            });

            deselectedFolders.forEach(folder => {
                newFolders.delete(folder);
                newFiles.delete(folder);
            });
        
            return {
                files: newFiles,
                folders: newFolders,
                newlySelectedFiles: newlySelected,
                deselectedFiles: deselected,
                previouslyIgnoredFiles: prev.previouslyIgnoredFiles
            };
        });
    }, [backendState.repository]);

    const handleLocalFileAdd = async (e) => {
        e.preventDefault();
        
        if (!localFileInput.trim()) {
            toast.warning('Input is empty!');
            return;
        }

        const newPaths = localFileInput
            .split(',')
            .map(path => path.trim())
            .filter(path => path.length > 0 && !localFiles.has(path));

        if (newPaths.length === 0) {
            toast.warning('No new files to add');
            return;
        }

        // Process each path
        const filesInRepo = [];
        const localFilesToAdd = [];
        const alreadyIgnoredFiles = [];
        const existingInRepo = [];

        newPaths.forEach(path => {
            if (backendState.repository.has(path)) {
                alreadyIgnoredFiles.push(path);
            } else if (isFileInRepoStructure(path, repositoryStructure)) {
                filesInRepo.push(path);
                existingInRepo.push(path);
                // Remove from local files if it exists
                setLocalFiles(prev => {
                    const next = new Set(prev);
                    next.delete(path);
                    return next;
                });
                setFileOrder(prev => prev.filter(f => f !== path));
                setLocalFileChanges(prev => {
                    const added = new Set(prev.added);
                    added.delete(path);
                    return { ...prev, added };
                });
            } else {
                localFilesToAdd.push(path);
            }
        });

        // Show warning for already ignored files
        if (alreadyIgnoredFiles.length > 0) {
            toast.warning(
                `${alreadyIgnoredFiles.length} file(s) already ignored in repository: ${alreadyIgnoredFiles.join(', ')}`
            );
        }

        // Show message for files that exist in repository
        if (existingInRepo.length > 0) {
            toast.info(
                `${existingInRepo.length} file(s) found in repository: ${existingInRepo.join(', ')}`
            );
        }

        // Only update local state
        if (filesInRepo.length > 0) {
            setGlobalSelections(prev => {
                const newFiles = new Set(prev.files);
                const newlySelected = new Set(prev.newlySelectedFiles);
                filesInRepo.forEach(file => {
                    newFiles.add(file);
                    newlySelected.add(file);
                });
                return {
                    ...prev,
                    files: newFiles,
                    newlySelectedFiles: newlySelected
                };
            });
        }

        if (localFilesToAdd.length > 0) {
            setLocalFiles(prev => new Set([...prev, ...localFilesToAdd]));
            setFileOrder(prev => [...prev, ...localFilesToAdd]);
            setLocalFileChanges(prev => ({
                ...prev,
                added: new Set([...prev.added, ...localFilesToAdd])
            }));
        }

        // Only show success message for files being added to the local list
        if (localFilesToAdd.length > 0) {
            toast.success(`${localFilesToAdd.length} file${localFilesToAdd.length > 1 ? 's' : ''} added to local list`);
        }

        // Clear input and hide input field
        setLocalFileInput('');
    };

    const handleLocalFileRemove = (file) => {
        const isNewFile = !initialLocalFiles.has(file);
    
        if (isNewFile) {
            setLocalFiles(prev => {
                const next = new Set(prev);
                next.delete(file);
                return next;
            });
            setFileOrder(prev => prev.filter(f => f !== file));
            setLocalFileChanges(prev => {
                const added = new Set(prev.added);
                added.delete(file);
                return { ...prev, added };
            });
        } else {
            setRemovedLocalFiles(prev => new Set([...prev, file]));
            setLocalFileChanges(prev => {
                const removed = new Set(prev.removed);
                removed.add(file);
                return { ...prev, removed };
            });
        }
    };

    const handleLocalFileRestore = (file) => {
        setLocalFiles(prev => new Set([...prev, file]));
        setRemovedLocalFiles(prev => {
            const next = new Set(prev);
            next.delete(file);
            return next;
        });
        setLocalFileChanges(prev => {
            const added = new Set(prev.added);
            const removed = new Set(prev.removed);
            removed.delete(file);
            if (!initialLocalFiles.has(file)) {
                added.add(file);
            }
            return { added, removed };
        });
    };

    const handleIgnoredFiles = (operation) => {
        const repositoryFiles = Array.from(
            operation === "add"
                ? globalSelections.newlySelectedFiles
                : globalSelections.deselectedFiles
        );
    
        const localFiles = Array.from(
            operation === "add"
                ? localFileChanges.added
                : localFileChanges.removed
        );
    
        if (repositoryFiles.length === 0 && localFiles.length === 0) {
            toast.warning('No files selected to remove or add.');
            return;
        }
    
        const mutateFunction = operation === "add" ? addIgnoredFiles : removeIgnoredFiles;
        const payload = { repoId, repositoryFiles, localFiles };
    
        mutateFunction(payload, {
            onSuccess: () => {
                // Update backend state
                setBackendState(prev => ({
                    repository: operation === "add"
                        ? new Set([...prev.repository, ...repositoryFiles])
                        : new Set([...prev.repository].filter(f => !repositoryFiles.includes(f))),
                    local: operation === "add"
                        ? new Set([...prev.local, ...localFiles])
                        : new Set([...prev.local].filter(f => !localFiles.includes(f)))
                }));

                // Update initial local files
                setInitialLocalFiles(prev => operation === "add"
                    ? new Set([...prev, ...localFiles])
                    : new Set([...prev].filter(f => !localFiles.includes(f)))
                );

                // Reset change tracking
                if (operation === "add") {
                    setLocalFileChanges(prev => ({
                        ...prev,
                        added: new Set()
                    }));
                    setGlobalSelections(prev => ({
                        ...prev,
                        newlySelectedFiles: new Set()
                    }));
                } else {
                    setLocalFileChanges(prev => ({
                        ...prev,
                        removed: new Set()
                    }));
                    setGlobalSelections(prev => ({
                        ...prev,
                        deselectedFiles: new Set()
                    }));
                }

                toast.success(`Successfully ${operation === "add" ? "added" : "removed"} ignored files`);
            },
            onError: (error) => {
                toast.error(`Failed to ${operation} ignored files: ${error.message}`);
            }
        });
    };

    const isAddDisabled = useCallback(() => {
        const hasNewFiles = globalSelections.newlySelectedFiles.size > 0;
        const hasNewLocalFiles = localFileChanges.added.size > 0;
        return !(hasNewFiles || hasNewLocalFiles);
    }, [globalSelections.newlySelectedFiles, localFileChanges.added]);
    
    const isRemoveDisabled = useCallback(() => {
        const hasDeselectedFiles = globalSelections.deselectedFiles.size > 0;
        const hasRemovedLocalFiles = localFileChanges.removed.size > 0;
        return !(hasDeselectedFiles || hasRemovedLocalFiles);
    }, [globalSelections.deselectedFiles, localFileChanges.removed]);

    const getCurrentBranchSelections = useCallback(() => {
        return {
            previouslyIgnoredFiles: Array.from(backendState.repository),
            selectedFolders: Array.from(globalSelections.folders),
            newlySelectedFiles: Array.from(globalSelections.newlySelectedFiles),
            deselectedFiles: Array.from(globalSelections.deselectedFiles)
        };
    }, [backendState.repository, globalSelections]);

    const countFilesAndDirectories = (structure) => {
        let fileCount = 0;
        let directoryCount = 0;

        const traverse = (items) => {
            if (!Array.isArray(items)) return;
            
            items.forEach(item => {
                if (item.type === 'blob') {
                    fileCount++;
                }
                if (item.type === 'tree') {
                    directoryCount++;
                    if (item.children) {
                        traverse(item.children);
                    }
                }
            });
        };

        traverse(structure);
        return { fileCount, directoryCount };
    };

    // Modify the useEffect for branch changes
    useEffect(() => {
        const processBranchChange = async () => {
            if (!repositoryStructure || 
                !localFiles.size || 
                isProcessingFiles || 
                !shouldProcessBranch ||
                processingRef.current) {
                return;
            }

            try {
                processingRef.current = true;
                setIsProcessingFiles(true);
                
                const localFilesArray = Array.from(localFiles);
                const filesInRepo = localFilesArray.filter(file => 
                    isFileInRepoStructure(file, repositoryStructure) && 
                    !backendState.repository.has(file)
                );

                if (filesInRepo.length > 0) {
                    // Single API call for each operation
                    await removeIgnoredFiles({
                        repoId,
                        repositoryFiles: [],
                        localFiles: filesInRepo
                    });

                    await addIgnoredFiles({
                        repoId,
                        repositoryFiles: filesInRepo,
                        localFiles: []
                    });

                    flushSync(() => {
                        // Update backend state
                        setBackendState(prev => ({
                            repository: new Set([...prev.repository, ...filesInRepo]),
                            local: new Set(localFilesArray.filter(f => !filesInRepo.includes(f)))
                        }));
                        
                        // Update global selections
                        setGlobalSelections(prev => ({
                            ...prev,
                            files: new Set([...prev.files, ...filesInRepo])
                        }));

                        // Update local files state
                        setLocalFiles(prev => {
                            const next = new Set(prev);
                            filesInRepo.forEach(file => next.delete(file));
                            return next;
                        });
                        
                        // Update file order
                        setFileOrder(prev => prev.filter(f => !filesInRepo.includes(f)));
                        
                        // Update local file changes
                        setLocalFileChanges(prev => {
                            const added = new Set(prev.added);
                            filesInRepo.forEach(file => added.delete(file));
                            return { ...prev, added };
                        });
                    });

                    toast.success(`${filesInRepo.length} files moved to repository`);
                }
            } finally {
                setIsProcessingFiles(false);
                setShouldProcessBranch(false);
                processingRef.current = false;
            }
        };

        processBranchChange();
    },[selectedBranch, repositoryStructure, shouldProcessBranch, localFiles, isProcessingFiles, addIgnoredFiles, removeIgnoredFiles, repoId, backendState.repository]);

    return (
        <div className="container mx-auto px-4 py-8">
            <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="container mx-auto px-4 py-8"
            >
                <Card className="w-full bg-white/50 dark:bg-gray-800/50 backdrop-blur-xl shadow-xl rounded-xl border-0 relative overflow-hidden">
                    <div className="absolute inset-0 bg-gradient-to-br from-primary/5 to-blue-600/5 pointer-events-none" />
                    
                    <CardHeader className="relative p-6 rounded-t-xl border-b border-gray-200 dark:border-gray-700">
                        <div className="flex items-center justify-between">
                            <div>
                                <div className="flex items-center space-x-4">
                                    <CardTitle className="text-3xl font-bold bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent">
                                        {repoName}
                                    </CardTitle>
                                    <Badge 
                                        variant="outline" 
                                        className="px-3 py-1 bg-white/50 dark:bg-gray-900/50 backdrop-blur-sm border-primary/20"
                                    >
                                        Repository Files
                                    </Badge>
                                </div>
                                <CardDescription className="mt-2 text-gray-600 dark:text-gray-300">
                                    Manage and configure which files and directories should be ignored or tracked in your repository. 
                                    Select files to add to or remove from your ignore list across different branches.
                                </CardDescription>
                            </div>
                            <Button 
                                variant="outline" 
                                onClick={onBack}
                                className="group/btn relative overflow-hidden border-primary/20 hover:border-primary transition-colors"
                            >
                                <div className="absolute inset-0 bg-gradient-to-r from-primary/10 to-blue-600/10 opacity-0 group-hover/btn:opacity-100 transition-opacity" />
                                <ArrowLeft className="mr-2 h-4 w-4" />
                                <span className="relative">Back to Repositories</span>
                            </Button>
                        </div>
                    </CardHeader>

                    <RepositoryContent 
                        {...{
                            repositoryStructure,
                            selectedBranch,
                            isLoading,
                            isError,
                            branches,
                            isBranchesLoading,
                            handleBranchChange,
                            handleFileSelectionChange,
                            globalSelections,
                            backendState,
                            localFiles,
                            removedLocalFiles,
                            handleLocalFileAdd,
                            handleLocalFileRemove,
                            handleLocalFileRestore,
                            initialLocalFiles,
                            fileOrder,
                            handleIgnoredFiles,
                            isAddDisabled,
                            isRemoveDisabled,
                            getCurrentBranchSelections,
                            countFilesAndDirectories,
                            localFileChanges,
                            localFileInput,
                            setLocalFileInput
                        }}
                    />
                </Card>
            </motion.div>
            
            <FileExistsModal
                isOpen={modalState.isOpen}
                onClose={() => setModalState(prev => ({ ...prev, isOpen: false }))}
                files={modalState.files}
                onConfirm={modalState.onConfirm}
                isLoading={modalState.isLoading}
            />
        </div>
    );
}

export default RepoDetailPage;