import React, { useState, useEffect } from 'react'
import { GitPullRequest, Code2, GitBranch, GitMerge, GitCommit } from 'lucide-react'
import { motion } from 'framer-motion'

export default function LoadingPage({ isLoading, message = "" }) {
  const [currentStep, setCurrentStep] = useState(0);
  const steps = ["Initializing", "Loading Resources", "Almost Ready"];

  // Cycle through steps automatically
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentStep((prev) => (prev + 1) % steps.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [steps.length]);

  // Background icons for decoration
  const backgroundIcons = [
    { Icon: Code2, position: "top-20 left-20", delay: 0 },
    { Icon: GitBranch, position: "top-40 right-32", delay: 0.2 },
    { Icon: GitMerge, position: "bottom-24 left-32", delay: 0.4 },
    { Icon: GitCommit, position: "bottom-32 right-24", delay: 0.6 },
  ];

  return (
    <motion.div 
      className="relative min-h-screen overflow-hidden bg-white dark:bg-gray-900"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      {/* Background Decorative Icons */}
      {backgroundIcons.map(({ Icon, position, delay }, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 0.1, scale: 1 }}
          transition={{ delay, duration: 0.5 }}
          className={`absolute ${position} text-gray-200 dark:text-gray-700`}
        >
          <Icon size={40} />
        </motion.div>
      ))}

      <div className="relative flex items-center justify-center min-h-screen">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="text-center max-w-lg w-full px-4"
        >
          {/* Logo */}
          <div className="relative mb-12">
            <div className="absolute inset-0 rounded-full bg-gradient-to-r from-primary/20 to-blue-600/20 blur-2xl" />
            <div className="relative w-32 h-32 mx-auto">
              <motion.div 
                className="absolute inset-0 rounded-full border-2 border-primary/30"
                animate={{ scale: [1, 1.2, 1] }}
                transition={{ 
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              />
              <motion.div 
                className="absolute inset-0 rounded-full border-2 border-blue-600/30"
                animate={{ scale: [1.2, 1, 1.2] }}
                transition={{ 
                  duration: 2,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              />
              <div className="absolute inset-4 rounded-full bg-white dark:bg-gray-900 flex items-center justify-center">
                <GitPullRequest className="w-12 h-12 text-primary" />
              </div>
            </div>
          </div>

          {/* Loading Text and Steps */}
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="space-y-8"
          >
            <div className="space-y-2">
              <h2 className="text-3xl font-bold bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent">
                Setting up ACRS
              </h2>
              <motion.p 
                key={currentStep}
                initial={{ opacity: 0, y: 5 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -5 }}
                className="text-gray-500 dark:text-gray-400"
              >
                {message || steps[currentStep]}
              </motion.p>
            </div>

            {/* Loading Steps Dots */}
            <div className="flex justify-center space-x-3">
              {steps.map((_, index) => (
                <motion.div
                  key={index}
                  className={`w-2 h-2 rounded-full ${
                    index === currentStep 
                      ? 'bg-primary' 
                      : 'bg-gray-300 dark:bg-gray-600'
                  }`}
                  animate={index === currentStep ? {
                    scale: [1, 1.5, 1],
                    opacity: [0.5, 1, 0.5]
                  } : {}}
                  transition={{
                    duration: 1,
                    repeat: Infinity,
                    ease: "easeInOut"
                  }}
                />
              ))}
            </div>

            {/* Additional Info */}
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1 }}
              className="text-sm text-gray-400 dark:text-gray-500"
            >
              {isLoading ? "This may take a few moments" : "Setup complete!"}
            </motion.div>
          </motion.div>
        </motion.div>
      </div>
    </motion.div>
  )
}