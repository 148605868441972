import React from 'react';
import { motion } from 'framer-motion';
import { FileText, Plus, X } from 'lucide-react';
import { Button } from '../../ui/button';

const LocalFilesList = ({ 
    files, 
    onRemove, 
    removedFiles, 
    onRestore, 
    initialFiles, 
    fileOrder 
}) => {
    if (files.size === 0 && removedFiles.size === 0) return null;

    // Create a map of unique files with their status
    const fileMap = new Map();
    const uniqueFileOrder = [...new Set(fileOrder)];

    uniqueFileOrder.forEach(file => {
        // For local files (not in initialFiles), only show if they're active
        if (!initialFiles.has(file)) {
            if (files.has(file) && !removedFiles.has(file)) {
                fileMap.set(file, {
                    path: file,
                    isRemoved: false,
                    isNew: true
                });
            }
        } 
        // For initial files, show both active and removed states
        else if (files.has(file) || (removedFiles.has(file) && initialFiles.has(file))) {
            fileMap.set(file, {
                path: file,
                isRemoved: removedFiles.has(file),
                isNew: false
            });
        }
    });

    // Convert map to array
    const allFiles = Array.from(fileMap.values());

    return (
        <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="mt-2 flex flex-wrap gap-2 p-3"
        >
            {allFiles.map((file) => {
                if (file.isRemoved && initialFiles.has(file.path)) {
                    return (
                        <motion.div
                            key={file.path}
                            initial={{ opacity: 0, scale: 0.9 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.9 }}
                            className="
                                group flex items-center
                                px-3 py-1 rounded-full
                                bg-red-50 dark:bg-red-900/20
                                border border-red-200 dark:border-red-800
                                backdrop-blur-sm
                                hover:bg-red-100 dark:hover:bg-red-900/30
                                transition-colors
                            "
                        >
                            <div className="flex items-center space-x-1.5 min-w-0">
                                <FileText className="h-3.5 w-3.5 text-red-400 flex-shrink-0" />
                                <span className="text-xs font-medium text-red-500 truncate max-w-[200px] line-through">
                                    {file.path}
                                </span>
                            </div>
                            <Button
                                variant="ghost"
                                size="sm"
                                onClick={() => onRestore(file.path)}
                                className="opacity-0 group-hover:opacity-100 transition-opacity h-5 w-5 p-0 ml-1 hover:bg-red-200 dark:hover:bg-red-800 rounded-full"
                                title="Restore file"
                            >
                                <Plus className="h-3 w-3 text-red-400" />
                            </Button>
                        </motion.div>
                    );
                }

                if (files.has(file.path)) {
                    return (
                        <motion.div
                            key={file.path}
                            initial={{ opacity: 0, scale: 0.9 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.9 }}
                            className={`
                                group flex items-center
                                px-3 py-1 rounded-full
                                ${file.isNew 
                                    ? 'bg-green-50 dark:bg-green-900/20 border-green-200 dark:border-green-800'
                                    : 'bg-primary/10 dark:bg-primary/20 border-primary/20 dark:border-primary/30'
                                }
                                border
                                backdrop-blur-sm
                                ${file.isNew
                                    ? 'hover:bg-green-100 dark:hover:bg-green-900/30'
                                    : 'hover:bg-primary/15 dark:hover:bg-primary/25'
                                }
                                transition-colors
                            `}
                        >
                            <div className="flex items-center space-x-1.5 min-w-0">
                                <FileText className={`h-3.5 w-3.5 ${file.isNew ? 'text-green-500' : 'text-primary'} flex-shrink-0`} />
                                <span className={`text-xs font-medium ${file.isNew ? 'text-green-600' : 'text-primary'} truncate max-w-[200px]`}>
                                    {file.path}
                                </span>
                            </div>
                            <Button
                                variant="ghost"
                                size="sm"
                                onClick={() => onRemove(file.path)}
                                className={`
                                    opacity-0 group-hover:opacity-100 transition-opacity h-5 w-5 p-0 ml-1 
                                    ${file.isNew 
                                        ? 'hover:bg-green-200 dark:hover:bg-green-800'
                                        : 'hover:bg-primary/20'
                                    } rounded-full
                                `}
                            >
                                <X className={`h-3 w-3 ${file.isNew ? 'text-green-500' : 'text-primary'}`} />
                            </Button>
                        </motion.div>
                    );
                }

                return null;
            })}
        </motion.div>
    );
};

export default LocalFilesList; 