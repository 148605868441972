import { toast } from 'sonner';

class ErrorHandler {
  static instance = null;
  lastErrorTimestamp = 0;
  errorThrottleTime = 1000; // 1 second between same errors

  static getInstance() {
    if (!this.instance) {
      this.instance = new ErrorHandler();
    }
    return this.instance;
  }

  showError(message, options = {}) {
    const currentTime = Date.now();
    
    // Prevent duplicate errors in quick succession
    if (currentTime - this.lastErrorTimestamp < this.errorThrottleTime) {
      return;
    }

    this.lastErrorTimestamp = currentTime;

    toast.error(message, {
      duration: 5000,
      ...options
    });
  }
}

// Export the singleton instance
export const errorHandler = ErrorHandler.getInstance();