import { useState, useEffect, useCallback } from 'react';
import { Brain, Loader2, Sparkles, AlertCircle } from 'lucide-react';
import { toast } from 'sonner';
import { motion } from 'framer-motion';

import { Button } from '../components/ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '../components/ui/card';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../components/ui/select';

import { useUpdateModel, useGetModels } from '../services/api-service'

const containerVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      when: "beforeChildren",
      staggerChildren: 0.1
    }
  }
};

const itemVariants = {
  hidden: { opacity: 0, x: -20 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.3 }
  }
};

export function SelectLLMModel({ user, isUserLoading }) {  
  const [modelLoading, setModelLoading] = useState(false)
  const { data: models = [] } = useGetModels();
  const { mutate: updateModel } = useUpdateModel();

  const getCurrentModelDisplay = useCallback((userModel) => {
    return models.find(m => m.value === userModel?.toLowerCase())?.display;
  }, [models]);

  const [selectedModel, setSelectedModel] = useState(getCurrentModelDisplay(user?.model_preference))
  // Update selected model when user data changes
  useEffect(() => {
    if (user?.model_preference) {
      setSelectedModel(getCurrentModelDisplay(user.model_preference));
    }
  }, [user, getCurrentModelDisplay]);

  const handleApplySelection = async () => {
    if (!selectedModel) {
      toast.error('Please select a model before applying.');
      return;
    }

    if (selectedModel === 'GPT-4' && !user?.is_admin) {
      toast.error('GPT-4 is only available to administrators.');
      return;
    }
  
    setModelLoading(true);
  
    try {
      const modelValue = models.find((m) => m.display === selectedModel)?.value;
  
      if (!modelValue) {
        throw new Error('Invalid model selection.');
      }
  
      await new Promise(resolve => setTimeout(resolve, 1000));
      await updateModel(modelValue);
      user.model_preference = modelValue;
      toast.success(`Successfully applied model: ${selectedModel}`);
    } catch (error) {
      if (error.response) {
        toast.error(`Failed to apply model: ${error.response.data.message || error.message}`);
      } else if (error.request) {
        toast.error('Network error: No response from server.');
      } else {
        toast.error(`An unexpected error occurred: ${error.message}`);
      }
    } finally {
      setModelLoading(false);
    }
  };
  

  if (isUserLoading) {
    return (
      <div className="container mx-auto px-4 py-8 flex items-center justify-center">
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.3 }}
          className="flex flex-col items-center space-y-4"
        >
          <Loader2 className="h-12 w-12 animate-spin text-primary" />
          <p className="text-gray-600 dark:text-gray-300 font-medium">
            Loading user preferences...
          </p>
        </motion.div>
      </div>
    );
  }

  return (
    <motion.div 
      className="container mx-auto px-4 py-8"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <Card className="max-w-2xl mx-auto bg-gradient-to-br from-white to-gray-50 dark:from-gray-800 dark:to-gray-900 border-2 border-primary/10">
        <CardHeader className="space-y-4">
          <motion.div 
            className="flex items-center space-x-3"
            variants={itemVariants}
          >
            <div className="p-2 rounded-lg bg-primary/10 text-primary">
              <Brain className="w-6 h-6" />
            </div>
            <CardTitle className="text-2xl font-bold bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent">
              Select LLM Model
            </CardTitle>
          </motion.div>
          <motion.div variants={itemVariants}>
            <CardDescription className="text-base">
              Choose the Large Language Model (LLM) that best fits your needs. Your selection will
              determine the AI's capabilities and performance.
            </CardDescription>
            {user?.current_model && (
              <div className="flex items-center space-x-2 mt-3 text-sm text-primary/80">
                <Sparkles className="h-4 w-4" />
                <p>Current Model: {getCurrentModelDisplay(user.current_model)}</p>
              </div>
            )}
          </motion.div>
        </CardHeader>
        <CardContent className="space-y-6">
          <motion.div 
            className="space-y-4"
            variants={itemVariants}
          >
            <Select onValueChange={setSelectedModel} value={selectedModel}>
              <SelectTrigger 
                id="model" 
                className="w-full border-2 border-primary/20 hover:border-primary/40 transition-colors"
              >
                <SelectValue placeholder="Select a model" />
              </SelectTrigger>
              <SelectContent>
                {models.map(({ display }) => {
                  const isGPT4Restricted = display === 'GPT-4' && !user?.is_admin;
                  
                  return (
                    <SelectItem 
                      key={display} 
                      value={display} 
                      disabled={isGPT4Restricted}
                      className={`
                        ${isGPT4Restricted ? 'opacity-50 cursor-not-allowed' : ''}
                        hover:bg-primary/10 transition-colors
                      `}
                    >
                      <div className="flex items-center space-x-2">
                        {display === 'GPT-4' && (
                          <Sparkles className="h-4 w-4 text-yellow-500" />
                        )}
                        <span>{display}</span>
                        {isGPT4Restricted && (
                          <span className="text-xs text-red-500 flex items-center">
                            <AlertCircle className="h-3 w-3 mr-1" />
                            Admin only
                          </span>
                        )}
                      </div>
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
          </motion.div>

          <motion.div variants={itemVariants}>
            <Button
              className="w-full bg-gradient-to-r from-primary to-blue-600 hover:from-primary/90 hover:to-blue-700 transition-all duration-300 shadow-lg hover:shadow-primary/25"
              onClick={handleApplySelection}
              disabled={getCurrentModelDisplay(user?.model_preference) === selectedModel || modelLoading}
            >
              {modelLoading ? (
                <motion.div 
                  className="flex items-center space-x-2"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  <Loader2 className="h-4 w-4 animate-spin" />
                  <span>Applying...</span>
                </motion.div>
              ) : (
                <motion.span
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                >
                  Apply Selection
                </motion.span>
              )}
            </Button>
          </motion.div>
        </CardContent>
      </Card>
    </motion.div>
  );
}