import { ENDPOINTS } from '../../constants/apiEndpoints';
import axios from 'axios';
import { create } from 'zustand';
import { persist } from 'zustand/middleware'; // Add persist middleware if you want to persist auth state
import { toast } from 'sonner'



// Enhanced auth store with persistence and additional states
export const useAuthStore = create(
  persist(
    (set, get) => ({
      accessToken: null,
      isLoading: false,
      isAuthenticated: false,
      vcrs: null,
      user: null,
      error: null,

      setAccessToken: (token) => 
        set({ 
          accessToken: token, 
          isLoading: false,
          isAuthenticated: !!token,
          error: null
        }),

      setUser: (userData) =>
        set({ user: userData }),

      setVcrs: (vcrs_name) =>
        set({ vcrs: vcrs_name }),

      clearToken: () => 
        set({ 
          accessToken: null, 
          isLoading: false, 
          isAuthenticated: false,
          user: null,
          error: null
        }),

      setLoading: (loading) => 
        set({ isLoading: loading }),

      setError: (error) =>
        set({ error, isLoading: false }),

      // Add a method to force clear state
      forceLogout: () => {
        set({ 
          accessToken: null, 
          isLoading: false, 
          isAuthenticated: false,
          user: null,
          vcrs: null,
          error: 'Backend unavailable'
        });
      },

      // Improved error handling
      handleNetworkError: (error) => {
        console.error('Network Error:', error);
        
        // Clear loading state
        set({ isLoading: false });

        // Show user-friendly error
        toast.error('Unable to connect to server. Please check your network connection.', {
          description: 'Some features may be limited.',
          duration: 5000
        });
      }

    }),
    {
      name: 'auth-storage', // Name for localStorage key
      partialize: (state) => ({ 
        accessToken: state.accessToken,
        user: state.user,
        vcrs: state.vcrs
      })
    }
  )
);

// Create axios instance with default config
export const api = axios.create({
  baseURL: ENDPOINTS.BASE,
  timeout: 60000, // Increase timeout to 60 seconds
  timeoutErrorMessage: 'Request timed out. Please check your network connection.',
});

// Request interceptor with error handling
// api.interceptors.request.use(
//   (config) => {
//     const token = useAuthStore.getState().accessToken;
    
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }

//     // Add request timestamp for timeout handling
//     config.metadata = { startTime: new Date() };
    
//     return config;
//   },
//   (error) => {
//     useAuthStore.getState().setError(error);
//     return Promise.reject(error);
//   }
// );

// Export a helper to reset the api instance (useful for testing)
// export const resetApiInstance = () => {
//   api.defaults.headers.common['Authorization'] = '';
// };

// Request Interceptor
api.interceptors.request.use(
  (config) => {
    // Add a custom timeout handler
    const source = axios.CancelToken.source();
    config.cancelToken = source.token;

    // Set a custom timeout
    const timeoutId = setTimeout(() => {
      source.cancel('Request timed out. The server is taking too long to respond.');
    }, 60000); // 20 seconds timeout

    // Cleanup timeout on request completion
    config.metadata = { timeoutId };

    return config;
  },
  (error) => Promise.reject(error)
);

// Response Interceptor
api.interceptors.response.use(
  (response) => {
    // Clear the timeout when request is successful
    if (response.config.metadata && response.config.metadata.timeoutId) {
      clearTimeout(response.config.metadata.timeoutId);
    }
    
    // Log request duration
    const duration = new Date() - response.config.metadata.startTime;
    console.debug(`Request to ${response.config.url} took ${duration}ms`);
    
    return response;
  },
  (error) => {
    // Clear any existing timeout
    if (error.config?.metadata?.timeoutId) {
      clearTimeout(error.config.metadata.timeoutId);
    }

    if (!error.response) {
      if (error.code === 'ECONNABORTED') {
        toast.error('Request timed out', {
          description: 'The server might be unavailable.',
          id: 'server-error'
        });
      } else if (!navigator.onLine) {
        toast.error('No Internet Connection', {
          description: 'Please check your network connectivity.',
          id: 'network-error'
        });
      } else {
        toast.error('Server Unreachable', {
          description: 'Unable to connect to the server. Please try again later.',
          id: 'server-error'
        });
      }

      // Force clear loading state
      useAuthStore.getState().forceLogout();
    }

    return Promise.reject(error);
  }
);

// Export a helper to reset the api instance (useful for testing)
export const resetApiInstance = () => {
  api.defaults.headers.common['Authorization'] = '';
};

// Request Interceptor
// api.interceptors.request.use(
//   (config) => {
//     // Check network connection
//     if (!navigator.onLine) {
//       useAuthStore.getState().handleNetworkError(new Error('No network connection'));
//       return Promise.reject(new Error('No network connection'));
//     }

//     const token = useAuthStore.getState().accessToken;
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => Promise.reject(error)
// );