import { 
  File, 
  FileText, 
  FileCode, 
  FileImage, 
  FileArchive 
} from 'lucide-react';

export const getFileIcon = (path) => {
    if (!path) return <File className="h-4 w-4 text-gray-500" />;
    
    const extension = path.split('.').pop().toLowerCase();
    
    switch (extension) {
      case 'txt':
        return <FileText className="h-4 w-4 text-blue-500" />;
      case 'js':
      case 'jsx':
      case 'ts':
      case 'tsx':
      case 'py':
      case 'java':
      case 'cpp':
      case 'c':
        return <FileCode className="h-4 w-4 text-green-500" />;
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'gif':
      case 'svg':
        return <FileImage className="h-4 w-4 text-purple-500" />;
      case 'zip':
      case 'rar':
      case 'tar':
      case 'gz':
        return <FileArchive className="h-4 w-4 text-red-500" />;
      default:
        return <File className="h-4 w-4 text-gray-500" />;
    }
};
