import React from 'react';
import { motion } from 'framer-motion';
import { AlertCircle, Plus } from 'lucide-react';
import { Button } from '../../ui/button';

const LocalFileForm = ({ 
    onSubmit, 
    localFileInput, 
    setLocalFileInput, 
    setShowLocalFileInput 
}) => {
    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit(e);
    };
  
    return (
        <motion.div 
            initial={{ opacity: 0, height: 0 }}
            animate={{ 
                opacity: 1, 
                height: "auto",
                transition: {
                    duration: 0.2,
                    ease: "easeOut"
                }
            }}
            exit={{ 
                opacity: 0, 
                height: 0,
                transition: {
                    duration: 0.2,
                    ease: "easeInOut"
                }
            }}
            className="border-b border-gray-200 dark:border-gray-700 overflow-hidden"
        >
            <motion.form 
                onSubmit={handleSubmit} 
                className="p-4"
                initial={{ y: -20 }}
                animate={{ 
                    y: 0,
                    transition: {
                        duration: 0.2,
                        ease: "easeOut",
                        delay: 0.1
                    }
                }}
                exit={{ 
                    y: -20,
                    transition: {
                        duration: 0.15,
                        ease: "easeIn"
                    }
                }}
            >
                <div className="space-y-4">
                    <div className="flex items-start space-x-3">
                        <div className="flex-1">
                            <div className="relative">
                                <textarea
                                    value={localFileInput}
                                    onChange={(e) => setLocalFileInput(e.target.value)}
                                    placeholder="Enter file paths separated by commas"
                                    className="w-full h-20 px-3 py-2 text-sm 
                                    bg-white/50 dark:bg-gray-900/50
                                    border border-gray-200 dark:border-gray-700
                                    rounded-lg shadow-sm
                                    backdrop-blur-sm
                                    focus:ring-2 focus:ring-primary/20 focus:border-primary
                                    placeholder:text-gray-400 
                                    transition-all duration-200 
                                    resize-none"
                                    autoFocus
                                />
                                <div className="absolute bottom-2 right-2 flex items-center space-x-2">
                                    <span className="text-xs text-gray-400">
                                        Press Enter to add
                                    </span>
                                </div>
                            </div>
                            <div className="mt-2 flex items-center space-x-2 text-xs text-gray-500">
                                <AlertCircle className="h-3.5 w-3.5 text-primary/60" />
                                <span>Example: path/to/file1.txt, path/to/file2.log</span>
                            </div>
                        </div>

                        <div className="flex flex-col space-y-2 min-w-[100px]">
                            <Button 
                                type="submit" 
                                size="sm"
                                disabled={!localFileInput.trim()}
                                className={`relative w-full ${
                                    localFileInput.trim() 
                                        ? 'bg-primary hover:bg-primary/90' 
                                        : 'bg-gray-100 dark:bg-gray-800 cursor-not-allowed'
                                } transition-colors duration-200`}
                            >
                                <Plus className="mr-2 h-4 w-4" />
                                Add
                            </Button>
                            <Button 
                                type="button" 
                                size="sm" 
                                variant="outline" 
                                onClick={() => {
                                    setShowLocalFileInput(false);
                                    setLocalFileInput('');
                                }}
                                className="w-full border-gray-200 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-800"
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>

                    <div className="flex items-center space-x-2 p-3 rounded-lg bg-primary/5 border border-primary/10">
                        <div className="flex-shrink-0">
                            <AlertCircle className="h-4 w-4 text-primary/60" />
                        </div>
                        <div className="flex-1 text-xs space-x-4">
                            <span className="text-gray-600 dark:text-gray-400">
                                <span className="font-medium text-primary/80">•</span> Use forward slashes
                            </span>
                            <span className="text-gray-600 dark:text-gray-400">
                                <span className="font-medium text-primary/80">•</span> Case-sensitive
                            </span>
                            <span className="text-gray-600 dark:text-gray-400">
                                <span className="font-medium text-primary/80">•</span> Relative to project root
                            </span>
                        </div>
                    </div>
                </div>
            </motion.form>
        </motion.div>
    );
};

export default LocalFileForm; 