// src/constants/apiEndpoints.js

// Use environment variable for API base URL, with a fallback to localhost
export const API_BASE_URL = process.env.REACT_APP_API_URL;

export const ENDPOINTS = {
  BASE: API_BASE_URL,
  LOGIN: `${API_BASE_URL}/oauth/login`,
  LOGOUT: `${API_BASE_URL}/oauth/logout`,
  REFRESH: `${API_BASE_URL}/api/auth/refresh`,
  GET_USER: `${API_BASE_URL}/oauth/users`,
  GET_REPOSITORIES: `${API_BASE_URL}/oauth/api/repositories`,
  MARK_REPOSITORY: `${API_BASE_URL}/oauth/api/set-webhook`,
  SELECT_MODEL: `${API_BASE_URL}/api/models/select`,
  UNMARK_REPOSITORY: `${API_BASE_URL}/oauth/api/delete-webhook`,
  TOGGLE_VULNERABILITY_CHECK: `${API_BASE_URL}/oauth/api/vulnerability_test`,
  GET_MODEL: `${API_BASE_URL}/oauth/api/get-models`,
  UPDATE_MODEL: `${API_BASE_URL}/oauth/api/set-model`,
  GET_BUSINESS_LOGIC: `${API_BASE_URL}/oauth/api/get_description`,
  ADD_BUSINESS_LOGIC: `${API_BASE_URL}/oauth/api/update_description`,
  DELETE_BUSINESS_LOGIC: `${API_BASE_URL}/oauth/api/delete_description`,
  REPOSITORY_STRUCTURE: `${API_BASE_URL}/oauth/api/repository/structure`,
  REPOSITORY_BRANCHES: `${API_BASE_URL}/oauth/api/get-repo-branches`,
  ADD_REPOSITORY: `${API_BASE_URL}/oauth/api/add-github-repos`,
  ADD_IGNORED_FILES: `${API_BASE_URL}/oauth/api/repository/add-ignored-files`,
  GET_IGNORED_FILES: `${API_BASE_URL}/oauth/api/repository/get-ignored-files`,
  REMOVE_IGNORED_FILES: `${API_BASE_URL}/oauth/api/repository/remove-ignored-files`,
};
