import React, { useState } from 'react';
import { ScrollArea } from '../../ui/scroll-area';
import { CardContent } from '../../ui/card';
import FileTreeViewWrapper from '../../file-tree';
import LocalFileForm from './LocalFileForm';
import LocalFilesList from './LocalFilesList';
import BranchSelector from './BranchSelector';
import FileStatistics from './FileStatistics';
import UpdateButton from './UpdateButton';
import { motion, AnimatePresence } from 'framer-motion';
import { AlertCircle, FolderOpen, Loader2, Upload, Plus, X } from 'lucide-react';
import { Button } from '../../ui/button';

const LoadingSpinner = ({ message }) => (
    <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.2 }}
        className="flex flex-col items-center justify-center py-12"
    >
        <div className="relative">
            <div className="absolute inset-0 blur-xl bg-gradient-to-r from-primary/30 to-blue-600/30 animate-pulse" />
            <Loader2 className="h-12 w-12 animate-spin text-primary relative" />
        </div>
        <p className="text-gray-600 dark:text-gray-400 mt-4">
            {message}
        </p>
    </motion.div>
);

const RepositoryContent = ({
    repositoryStructure,
    selectedBranch,
    isLoading,
    isError,
    branches,
    isBranchesLoading,
    handleBranchChange,
    handleFileSelectionChange,
    globalSelections,
    backendState,
    localFiles,
    removedLocalFiles,
    handleLocalFileAdd,
    handleLocalFileRemove,
    handleLocalFileRestore,
    initialLocalFiles,
    fileOrder,
    handleIgnoredFiles,
    isAddDisabled,
    isRemoveDisabled,
    getCurrentBranchSelections,
    countFilesAndDirectories,
    localFileChanges,
    localFileInput,
    setLocalFileInput
}) => {
    const [isFileListExpanded, setIsFileListExpanded] = useState(false);
    const [showLocalFileInput, setShowLocalFileInput] = useState(false);

    const { fileCount, directoryCount } = countFilesAndDirectories(repositoryStructure || []);
    const currentSelections = getCurrentBranchSelections();

    const showLoading = isLoading || isBranchesLoading;
    const showError = !showLoading && isError;
    const showEmptyState = !showLoading && !showError && (!repositoryStructure || repositoryStructure.length === 0);
    const showFileTree = !showLoading && !showError && repositoryStructure && repositoryStructure.length > 0;

    return (
        <CardContent className="p-0 relative">
            <div className="sticky top-0 z-10 bg-white/80 dark:bg-gray-800/80 backdrop-blur-xl border-b border-gray-200 dark:border-gray-700 shadow-sm">
                <div className="flex justify-between items-center p-4">
                    <FileStatistics
                        fileCount={fileCount}
                        directoryCount={directoryCount}
                        totalFiles={Array.from(globalSelections.files)}
                        folders={Array.from(globalSelections.folders)}
                        totalCount={globalSelections.files.size + globalSelections.folders.size}
                        isFileListExpanded={isFileListExpanded}
                        setIsFileListExpanded={setIsFileListExpanded}
                        getCurrentBranchSelections={getCurrentBranchSelections}
                    />
                    <UpdateButton
                        isAddDisabled={isAddDisabled}
                        isRemoveDisabled={isRemoveDisabled}
                        handleIgnoredFiles={handleIgnoredFiles}
                        currentSelections={currentSelections}
                        localFileChanges={localFileChanges}
                    />
                </div>
            </div>

            <ScrollArea className="h-[calc(100vh-20rem)]">
                <div className="p-4 space-y-6">
                    {/* Local Files Section */}
                    <div className="mb-6">
                        <motion.div className="rounded-lg overflow-hidden border border-gray-200 dark:border-gray-700">
                            <div className="flex items-center justify-between p-4 bg-gradient-to-r from-primary/5 to-blue-600/5 border-b border-gray-200 dark:border-gray-700">
                                <div className="flex items-center space-x-3">
                                    <div className="bg-white/50 dark:bg-gray-900/50 backdrop-blur-sm p-2 rounded-lg">
                                        <Upload className="h-5 w-5 text-primary" />
                                    </div>
                                    <div>
                                        <h3 className="font-semibold text-gray-800 dark:text-gray-200">Local Files</h3>
                                        <p className="text-xs text-gray-600 dark:text-gray-400">
                                        Add local file paths that should be consistently ignored across all branches. 
                                        Use relative paths from the project root.
                                    </p>                                     
                                    </div>
                                </div>
                                <Button
                                    variant="outline"
                                    size="sm"
                                    onClick={() => setShowLocalFileInput(!showLocalFileInput)}
                                    className="group/btn relative overflow-hidden border-primary/20 hover:border-primary transition-colors"
                                >
                                    <div className="absolute inset-0 bg-gradient-to-r from-primary/10 to-blue-600/10 opacity-0 group-hover/btn:opacity-100 transition-opacity" />
                                    {showLocalFileInput ? (
                                        <>
                                            <X className="mr-2 h-4 w-4" />
                                            <span className="relative">Close</span>
                                        </>
                                    ) : (
                                        <>
                                            <Plus className="mr-2 h-4 w-4" />
                                            <span className="relative">Add File Path</span>
                                        </>
                                    )}
                                </Button>
                            </div>

                            <AnimatePresence mode="wait">
                                {showLocalFileInput && (
                                    <LocalFileForm
                                        onSubmit={handleLocalFileAdd}
                                        localFileInput={localFileInput}
                                        setLocalFileInput={setLocalFileInput}
                                        setShowLocalFileInput={setShowLocalFileInput}
                                    />
                                )}
                            </AnimatePresence>

                            {(localFiles.size > 0 || removedLocalFiles.size > 0) && (
                                <LocalFilesList 
                                    files={localFiles}
                                    removedFiles={removedLocalFiles}
                                    onRemove={handleLocalFileRemove}
                                    onRestore={handleLocalFileRestore}
                                    initialFiles={initialLocalFiles}
                                    fileOrder={fileOrder}
                                />
                            )}
                        </motion.div>
                    </div>

                    {/* Repository Structure Section */}
                    <div className="bg-white/50 dark:bg-gray-800/50 backdrop-blur-xl min-h-full rounded-lg border border-gray-200 dark:border-gray-700">
                        <div className="p-4 border-b border-gray-200 dark:border-gray-700 bg-gradient-to-r from-primary/5 to-blue-600/5 rounded-t-lg">
                            <div className="flex items-center justify-between mb-4">
                                <div>
                                    <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">Repository Files</h3>
                                    <p className="text-xs text-gray-600 dark:text-gray-400">
                                        Select files and folders to ignore or track. Changes apply to the current branch and can help 
                                        manage which files are monitored by your version control system.
                                    </p>                                
                                </div>
                                <BranchSelector
                                    branches={branches}
                                    isBranchesLoading={isBranchesLoading}
                                    selectedBranch={selectedBranch}
                                    onBranchChange={handleBranchChange}
                                />
                            </div>
                        </div>
                        
                        <div className="p-4">
                            <AnimatePresence mode="wait">
                                {showLoading && (
                                    <LoadingSpinner 
                                        message={
                                            isBranchesLoading 
                                                ? 'Loading repository branches...' 
                                                : 'Loading repository structure...'
                                        }
                                    />
                                )}
                                
                                {showError && (
                                    <motion.div 
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        transition={{ duration: 0.2 }}
                                        className="flex flex-col items-center justify-center py-12 bg-red-50/50 dark:bg-red-900/20 backdrop-blur-sm rounded-lg"
                                    >
                                        <AlertCircle className="h-12 w-12 text-red-500 mb-4" />
                                        <h3 className="text-lg font-semibold text-red-500">Error Loading Repository</h3>
                                        <p className="text-sm text-gray-500 dark:text-gray-400 mt-2">Unable to fetch repository details.</p>
                                    </motion.div>
                                )}
                                
                                {showEmptyState && (
                                    <motion.div 
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        transition={{ duration: 0.2 }}
                                        className="text-center py-12 bg-gray-50/50 dark:bg-gray-900/50 backdrop-blur-sm rounded-lg"
                                    >
                                        <FolderOpen className="h-12 w-12 mx-auto mb-4 text-gray-400 dark:text-gray-500" />
                                        <p className="text-gray-500 dark:text-gray-400">No repository structure found for {selectedBranch} branch</p>
                                    </motion.div>
                                )}
                                
                                {showFileTree && (
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        transition={{ duration: 0.2 }}
                                    >
                                        <FileTreeViewWrapper 
                                            key={selectedBranch}
                                            data={repositoryStructure} 
                                            onSelectionChange={handleFileSelectionChange}
                                            initialSelectedFiles={Array.from(globalSelections.files)}
                                            initialSelectedFolders={Array.from(globalSelections.folders)}
                                            backendState={backendState}
                                        />
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </div>
                    </div>
                </div>
            </ScrollArea>
        </CardContent>
    );
};

export default RepositoryContent;