import React, { useEffect, useRef } from 'react';
import { Badge } from '../../ui/badge';
import { FolderOpen, Files, ChevronUp, ChevronDown } from 'lucide-react';
import { Card, CardContent } from '../../ui/card';
import { ScrollArea } from '../../ui/scroll-area';
import { getFileIcon } from '../../file-tree/utils/fileIcons';
import { motion, AnimatePresence } from 'framer-motion';

const FileStatistics = ({
    fileCount,
    directoryCount,
    totalFiles,
    folders,
    totalCount,
    isFileListExpanded,
    setIsFileListExpanded,
    getCurrentBranchSelections
}) => {
    const cardRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (cardRef.current && !cardRef.current.contains(event.target)) {
                setIsFileListExpanded(false);
            }
        };

        if (isFileListExpanded) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isFileListExpanded, setIsFileListExpanded]);

    return (
      <div className="flex items-center">
        <div className="flex items-center space-x-3">
          <Badge variant="outline" className="flex items-center px-4 py-1.5 bg-purple-50 dark:bg-purple-900/20 text-purple-700 dark:text-purple-300 border-purple-200 dark:border-purple-800">
            <FolderOpen className="mr-2 h-4 w-4" />
            <span className="font-medium">{directoryCount} Directories</span>
          </Badge>
          <Badge variant="outline" className="flex items-center px-4 py-1.5 bg-indigo-50 dark:bg-indigo-900/20 text-indigo-700 dark:text-indigo-300 border-indigo-200 dark:border-indigo-800">
            <Files className="mr-2 h-4 w-4" />
            <span className="font-medium">{fileCount} Files</span>
          </Badge>
          
          {totalCount > 0 && (
            <div className="relative" ref={cardRef}>
              <Badge 
                variant="outline" 
                className="flex items-center px-4 py-1.5 bg-blue-50 dark:bg-blue-900/20 text-blue-700 dark:text-blue-300 border-blue-200 dark:border-blue-800 cursor-pointer hover:bg-blue-100 dark:hover:bg-blue-900/30 transition-colors"
                onClick={() => setIsFileListExpanded(!isFileListExpanded)}
              >
                <Files className="mr-2 h-4 w-4" />
                <span className="font-medium">{totalCount} Selected</span>
                {isFileListExpanded 
                  ? <ChevronUp className="ml-2 h-4 w-4" /> 
                  : <ChevronDown className="ml-2 h-4 w-4" />
                }
              </Badge>
              
              <AnimatePresence>
                {isFileListExpanded && (
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.2 }}
                    className="absolute right-0 z-10 mt-2 w-96"
                  >
                    <Card className="border-2 border-blue-100 shadow-lg bg-white rounded-lg overflow-hidden">
                      <CardContent className="p-4">
                        <ScrollArea className="h-64">
                          <div className="space-y-4">
                            {folders.length > 0 && (
                              <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: 0.1 }}
                              >
                                <h4 className="text-sm font-semibold mb-2 text-gray-700">
                                  Selected Folders ({folders.length})
                                </h4>
                                <ul className="space-y-1.5">
                                  {folders.map((folderPath, index) => (
                                    <motion.li
                                      key={`folder-${index}`}
                                      initial={{ opacity: 0, x: -10 }}
                                      animate={{ opacity: 1, x: 0 }}
                                      transition={{ delay: index * 0.03 }}
                                      className="flex items-center bg-gray-50 p-2 rounded-md"
                                    >
                                      <FolderOpen className="mr-2 h-4 w-4 text-amber-500" />
                                      <span className="text-sm text-gray-600 truncate">{folderPath}</span>
                                    </motion.li>
                                  ))}
                                </ul>
                              </motion.div>
                            )}
                            
                            {totalFiles.length > 0 && (
                              <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: 0.1 }}
                              >
                                <h4 className="text-sm font-semibold mb-2 text-gray-700">
                                  Selected Files ({totalFiles.length})
                                </h4>
                                <ul className="space-y-1.5">
                                  {totalFiles.map((filePath, index) => (
                                    <motion.li
                                      key={`file-${index}`}
                                      initial={{ opacity: 0, x: -10 }}
                                      animate={{ opacity: 1, x: 0 }}
                                      transition={{ delay: index * 0.03 }}
                                      className="flex items-center bg-gray-50 p-2 rounded-md"
                                    >
                                      {getFileIcon(filePath)}
                                      <span className="ml-2 text-sm text-gray-600 truncate flex-1">
                                        {filePath}
                                      </span>
                                      {getCurrentBranchSelections().previouslyIgnoredFiles.includes(filePath) && (
                                        <Badge className="ml-2 bg-gray-100 text-gray-600">Previous</Badge>
                                      )}
                                    </motion.li>
                                  ))}
                                </ul>
                              </motion.div>
                            )}
                          </div>
                        </ScrollArea>
                      </CardContent>
                    </Card>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          )}
        </div>
      </div>
    );
  };

export default FileStatistics; 