import React from 'react';
import { Link } from 'react-router-dom';
import {  
  ArrowLeft, 
  Home,
  CloudOff
} from 'lucide-react';
import { Button } from "../components/ui/button";
import { motion } from 'framer-motion';

export function NotFoundComponent() {
  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
      className="min-h-screen bg-gradient-to-b from-white to-gray-50 dark:from-gray-900 dark:to-gray-800 flex items-center justify-center relative overflow-hidden"
    >
      {/* Background Decorations */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -top-1/2 -left-1/2 w-full h-full bg-gradient-to-br from-primary/5 to-blue-600/5 rounded-full transform rotate-45" />
        <div className="absolute -bottom-1/2 -right-1/2 w-full h-full bg-gradient-to-tl from-primary/5 to-blue-600/5 rounded-full transform -rotate-45" />
        {[...Array(20)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute h-2 w-2 bg-primary/10 rounded-full"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
            }}
            animate={{
              scale: [1, 1.5, 1],
              opacity: [0.3, 0.8, 0.3],
            }}
            transition={{
              duration: 3 + Math.random() * 2,
              repeat: Infinity,
              delay: Math.random() * 2,
            }}
          />
        ))}
      </div>

      <div className="relative z-10 text-center max-w-2xl mx-auto px-4 py-12">
        {/* Error Header */}
        <motion.div
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ type: "spring", stiffness: 260, damping: 20 }}
          className="flex flex-col items-center justify-center mb-8"
        >
          <motion.div
            className="relative mb-6"
            animate={{
              rotate: [0, 10, -10, 0],
              scale: [1, 1.1, 1]
            }}
            transition={{
              duration: 4,
              repeat: Infinity,
              ease: "easeInOut" 
            }}
          >
            <div className="absolute -inset-4 bg-destructive/10 rounded-full blur-xl animate-pulse"></div>
            <CloudOff className="w-24 h-24 text-destructive/80" />
          </motion.div>
          
          <motion.h1 
            className="text-8xl font-black tracking-tighter mb-4"
            animate={{ 
              textShadow: ["0 0 10px rgba(0,0,0,0)", "0 0 20px rgba(var(--primary),0.3)", "0 0 10px rgba(0,0,0,0)"]
            }}
            transition={{ duration: 2, repeat: Infinity }}
          >
            <span className="bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent">
              404
            </span>
          </motion.h1>
        </motion.div>

        {/* Error Message */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="space-y-6 mb-12"
        >
          <h2 className="text-5xl font-bold bg-gradient-to-r from-primary to-blue-600 bg-clip-text text-transparent">
            Page Not Found
          </h2>
          <p className="text-xl text-gray-600 dark:text-gray-300 leading-relaxed max-w-lg mx-auto">
            Oops! Looks like you've ventured into uncharted territory. 
            The page you're seeking seems to have wandered off into the digital void.
          </p>
        </motion.div>

        {/* Navigation Buttons */}
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
          className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4"
        >
          <Link to="/">
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="group"
            >
              <Button 
                variant="outline" 
                className="relative overflow-hidden bg-white dark:bg-gray-800 border-primary/20 hover:border-primary px-6 py-3 h-auto"
              >
                <div className="absolute inset-0 bg-gradient-to-r from-primary/10 to-blue-600/10 opacity-0 group-hover:opacity-100 transition-opacity" />
                <motion.div className="flex items-center space-x-2">
                  <motion.div
                    animate={{
                      x: [0, -4, 0],
                    }}
                    transition={{
                      duration: 1.5,
                      repeat: Infinity,
                      ease: "easeInOut",
                    }}
                  >
                    <Home className="w-5 h-5" />
                  </motion.div>
                  <span className="font-medium">Return Home</span>
                </motion.div>
              </Button>
            </motion.div>
          </Link>
          
          <Link to="/contact">
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="group"
            >
              <Button 
                variant="default" 
                className="relative overflow-hidden bg-gradient-to-r from-primary to-blue-600 hover:from-primary/90 hover:to-blue-600/90 px-6 py-3 h-auto"
              >
                <motion.div className="flex items-center space-x-2">
                  <motion.div
                    animate={{
                      x: [0, -4, 0],
                    }}
                    transition={{
                      duration: 1.5,
                      repeat: Infinity,
                      ease: "easeInOut",
                    }}
                  >
                    <ArrowLeft className="w-5 h-5" />
                  </motion.div>
                  <span className="font-medium">Contact Support</span>
                </motion.div>
              </Button>
            </motion.div>
          </Link>
        </motion.div>
      </div>
    </motion.div>
  );
}