import React, { createContext, useContext, useEffect } from 'react';
import { useAuthStore } from './auth-interceptor';
import { useNavigate } from 'react-router-dom';
import { useLogout } from '../../services/api-service';
import { toast } from 'sonner';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const accessToken = useAuthStore((state) => state.accessToken);
  const isLoading = useAuthStore((state) => state.isLoading);
  const error = useAuthStore((state) => state.error);
  const navigate = useNavigate();
  const handleLogout = useLogout();

  // Add a global error handler
  useEffect(() => {
    if (error) {
      // Redirect to login or handle error state
      navigate('/login');
    }
  }, [error, navigate]);

  // Optional: Add network status listener
  useEffect(() => {
    const handleOnline = () => {
      toast.success('Network connection restored', {
        description: 'You can now continue using the application.'
      });
    };
  
    const handleOffline = () => {
      toast.error('Network connection lost', {
        description: 'Please check your internet connection.',
        duration: 5000
      });
    };
  
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
  
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        isLoading,
        error,
        handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
export const useAuth = () => {
  return useContext(AuthContext);
};
