import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { useOAuthCallback } from '../services/api-service';
import LoadingPage from './loading-page';

const OAuthCallback = () => {
  const navigate = useNavigate();
  const handleCallback = useOAuthCallback();
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    if (isProcessing) return;

    setIsProcessing(true)
    
    const processOAuth = async () => {
      const searchParams = new URLSearchParams(window.location.search);

      // const error = searchParams.get('error');
      // if (error) {
      //   const errorMessage = searchParams.get('message') || 'An error occurred during OAuth.';
      //   toast.error(`OAuth error: ${errorMessage}`);
      //   navigate('/login');
      //   setIsProcessing(false);
      //   return;
      // }

      try {
        const data = await handleCallback(searchParams);        
        if (data?.user_id) {
          toast.success('Authentication successful!');
          setTimeout(() => navigate('/dashboard'), 0);
        } else {
          toast.error('Authentication failed: No token received.');
          setTimeout(() => navigate('/login'), 0);
        }
      } catch (error) {
        toast.error(`Failed to process authentication: ${error.message}`);
        navigate('/login');
      } finally {
        setIsProcessing(false);
      }
    };

    processOAuth();
  }, [handleCallback, navigate, isProcessing]);

  return (
    <div>
        <LoadingPage />
    </div>
  );
};

export default OAuthCallback;